/*
 * @Description: 路由配置
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:58:48
 */
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [

    {
        path: '/',
        name: 'Home',
        component: () =>
            import ('../views/Home.vue')
    },
    {
        path: '/error',
        name: 'Error',
        component: () =>
            import ('../components/Error.vue')
    },
    {
        path: '/goods',
        name: 'Goods',
        component: () =>
            import ('../views/Goods.vue')
    },
    {
        path: '/about',
        name: 'About',
        component: () =>
            import ('../views/About.vue')
    },
    {
        path: '/goods/details',
        name: 'Details',
        component: () =>
            import ('../views/Details.vue')
    },
    {
        path: '/shoppingCart',
        name: 'ShoppingCart',
        component: () =>
            import ('../views/ShoppingCart.vue'),
        meta: {
            requireAuth: true // 需要验证登录状态
        }
    },
    {
        path: '/collect',
        name: 'Collect',
        component: () =>
            import ('../views/Collect.vue'),
        meta: {
            requireAuth: true // 需要验证登录状态
        }
    },
    {
        path: '/order',
        name: 'Order',
        component: () =>
            import ('../views/Order.vue'),
        meta: {
            requireAuth: true // 需要验证登录状态
        }
    },
    {
        path: '/confirmOrder',
        name: 'ConfirmOrder',
        component: () =>
            import ('../views/ConfirmOrder.vue'),
        meta: {
            requireAuth: true // 需要验证登录状态
        }
    },
    {
        //个人中心主页
        path: '/user/Index',
        name: 'userIndex',
        component: () =>
            import ('../views/user/Index.vue'),
        meta: {
            requireAuth: true // 需要验证登录状态
        }

    },
    {
        //个人中心-收货地址
        path: '/user/address',
        name: 'userAddress',
        component: () =>
            import ('../views/user/profile/shipping-address'),
        meta: {
            requireAuth: true // 需要验证登录状态
        }

    },
    {
        //账户管理-个人信息
        path: '/user/info',
        name: 'userInfo',
        component: () =>
            import ('../views/user/profile/index'),
        meta: {
            requireAuth: true // 需要验证登录状态
        }

    },
    {
        //账户管理-账户安全
        path: '/user/account',
        name: 'userAccount',
        component: () =>
            import ('../views/user/account/index'),
        meta: {
            requireAuth: true // 需要验证登录状态
        }

    },
    {
        //订单中心-我的订单
        path: '/order/index',
        name: 'OrderInfo',
        component: () =>
            import ('../views/order/index.vue'),
        meta: {
            requireAuth: true // 需要验证登录状态
        }
    },
    {
        //订单详情
        path: '/order/detail',
        name: 'OrderDetail',
        component: () =>
            import ('../views/order/detail.vue'),
        meta: {
            requireAuth: true // 需要验证登录状态
        }
    },
    {
        //订单支付
        path: '/order/pay',
        name: 'OrderPay',
        component: () =>
            import ('../views/order/pay/index.vue'),
        meta: {
            requireAuth: true // 需要验证登录状态
        }
    },
    {
        //订单支付成功
        path: '/order/paysuccess',
        name: 'OrderPaySucces',
        component: () =>
            import ('../views/order/pay/pay-success.vue'),
        meta: {
            requireAuth: true // 需要验证登录状态
        }
    },
    {
        //商品详情
        path: '/goods/info',
        name: 'GoodsDetails',
        component: () =>
            import ('../views/goods/index.vue')
    },
    {
        //优惠券回收
        path: '/user/exchange',
        name: 'exchange',
        component: () =>
            import ('../views/user/exchange/index.vue'),
        meta: {
            requireAuth: true // 需要验证登录状态
        }
    },
    {
        //卡券订单
        path: '/user/exchangeorder',
        name: 'exchangeorder',
        component: () =>
            import ('../views/user/exchange/exchangeOrder.vue'),
        meta: {
            requireAuth: true // 需要验证登录状态
        }
    },
    {
        //充值总入口，支付宝充值是跳转到支付宝的页面，微信是生成一个二维码
        path: '/user/recharge',
        name: 'recharge',
        component: () =>
            import ('../views/user/recharge/index.vue'),
        meta: {
            requireAuth: true // 需要验证登录状态
        }
    },
    {
        //云淘豆流水记录
        path: '/user/balancehistory',
        name: 'balancehistory',
        component: () =>
            import ('../views/user/balancehistory/index.vue'),
        meta: {
            requireAuth: true // 需要验证登录状态
        }
    },
    {
        //充值异常
        path: '/user/rechargeError',
        name: 'rechargeError',
        component: () =>
            import ('../views/user/recharge/rechargeError.vue'),
        meta: {
            requireAuth: true // 需要验证登录状态
        }
    },
    {
        //帮助
        path: '/help',
        name: 'helpIndex',
        component: () =>
            import ('../views/help/index.vue'),
    },
    {
        //用户注册协议
        path: '/login/agreement',
        name: 'agreement',
        component: () =>
            import ('../views/help/agreement.vue'),
    },
    {
        //卡卷兑换信息
        path: '/user/exchangeinfo',
        name: 'exchangeInfo',
        component: () =>
            import ('../views/user/exchange/info.vue'),
        meta: {
            requireAuth: true // 需要验证登录状态
        }
    },
    {
        //icp审核付费阅读
        path: '/icp/read',
        name: 'icpRead',
        component: () =>
            import ('../views/icp/index.vue'),
    },
    {
        //icp审核文章内容
        path: '/icp/word',
        name: 'icpWord',
        component: () =>
            import ('../views/icp/word.vue'),
    },
    {
        //icp商家入驻协议
        path: '/icp/article',
        name: 'article',
        component: () =>
            import ('../views/icp/article.vue'),
    },
    {
        //我要采购
        path: '/user/purchase',
        name: 'purchaseIndex',
        component: () =>
            import ('../views/user/purchase/index.vue'),
        meta: {
            requireAuth: true // 需要验证登录状态
        }
    },
    {
        //我要留言
        path: '/user/message',
        name: 'purchaseMessage',
        component: () =>
            import ('../views/user/purchase/message.vue'),
        meta: {
            requireAuth: true // 需要验证登录状态
        }
    },
]

const router = new Router({
    // base: '/dist',
    // mode: 'history',
    routes
})

/* 由于Vue-router在3.1之后把$router.push()方法改为了Promise。所以假如没有回调函数，错误信息就会交给全局的路由错误处理。
vue-router先报了一个Uncaught(in promise)的错误(因为push没加回调) ，然后再点击路由的时候才会触发NavigationDuplicated的错误(路由出现的错误，全局错误处理打印了出来)。*/
// 禁止全局路由错误处理打印
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

export default router