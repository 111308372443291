export default {
    helpMenus: [
        {
            title: "新手指南",
            menusItem: [
                // {
                //     name: "常见问题",
                //     id: 1,
                //     html: '    <style type="text/css">' +
                //         "    a.helpcur{ color:#036ab7;text-decoration:underline;}" +
                //         "    a.helpcur:hover{ color:#036ab7;text-decoration:none;}</style>" +
                //         '    <div style="border-bottom: #e6e6e6 1px solid; padding-bottom: 14px; line-height: 23px; font-size: 14px; padding-top: 14px"><strong style="line-height: 30px; color: #333333; font-size: 14px">问：礼品卡使用规则？</strong><br>' +
                //         '    <span style="color: #717171">答：享购礼品卡分“电子礼品卡”和“实物礼品卡”(以下统称礼品卡)，可购买云淘通(www.yuntaowulian.com)及享购全国实体店面的任何商品。购买商品使用的金额及配送费用将从礼品卡中扣除，每笔订单可以使用多张礼品卡，没有使用完的余额将仍然保留在礼品卡中。如果当次消费金额超过礼品卡的金额，不足部分需由顾客使用其它支付方式付清。您在使用礼品卡前，请登录云淘通会员中心“我的礼品卡”进行绑定。<br>' +
                //         '    <span style="color: #ff0000">如果您在实体店面使用实物礼品卡时，也可由销售人员帮助绑定礼品卡后，才可使用。</span> <br>' +
                //         "    使用礼品卡购买商品，订单成功结束后，云淘豆将自动累计到激活的会员账户中。如果需要查看礼品卡中的金额，请登录云淘通会员中心，在“我的享购”页面中，选择“我的礼品卡”，进行查看。<br>" +
                //         "    <strong>规则：</strong> <br>" +
                //         "    电子礼品卡购买只提供在线支付和银行转账方式，不支持退换服务； <br>" +
                //         "    礼品卡有效期为3年，可在有效期内多次使用；<br>" +
                //         "    使用礼品卡支付的订单，若发生退货，订单金额将自动退回到您的会员账户“我的礼品卡”中，有效期不变；<br>" +
                //         "    请注意礼品卡的有效期，在有效期到达之前使用；<br>" +
                //         "    单张礼品卡可分次使用；单笔订单，可使用多张礼品卡；<br>" +
                //         "    享购礼品卡不能折现，卡内余额不退，暂不支持充值；</span></div>" +
                //         '    <div style="border-bottom: #e6e6e6 1px solid; padding-bottom: 10px; line-height: 23px; font-size: 14px; padding-top: 10px"><strong style="line-height: 30px; color: #333333; font-size: 14px">问：怎么知道礼品卡里还有多少钱，有效期是什么时候？</strong><br>' +
                //         '    <span style="color: #717171">答：您好，请登录云淘通会员中心，在“我的享购”页面中，选择“我的礼品卡”，进入“我的礼品卡”页面进行查看。</span></div>' +
                //         '    <div style="border-bottom: #e6e6e6 1px solid; padding-bottom: 10px; line-height: 23px; font-size: 14px; padding-top: 10px"><strong style="line-height: 30px; color: #333333; font-size: 14px">问：礼品卡面值能自已定制吗？</strong><br>' +
                //         '    <span style="color: #717171">答：您好，云淘通针对企业大客户提供大宗采购礼品卡一站式服务，针对客户订购的数量、金额，可单独按需求定制VIP礼品卡，为客户提供更方便的服务。<br>' +
                //         '    咨询及购买，请拨打享购客服热线</span><span style="color: #ff0000">400-628-8666</span></div>' +
                //         '    <div style="border-bottom: #e6e6e6 1px solid; padding-bottom: 10px; line-height: 23px; font-size: 14px; padding-top: 10px"><strong style="line-height: 30px; color: #333333; font-size: 14px">问：购买礼品卡能货到付款吗？</strong><br>' +
                //         '    <span style="color: #717171">答：您好，礼品卡的购买目前还没有开通货到付款服务，电子礼品卡购买只提供在线支付和银行转账方式，不支持退换服务。实物礼品卡，您可拨打享购客服热线<span style="color: #ff0000">400-628-8666</span>进行购买。发票将按您的需求，由网站直接开具普通发票给您。</span></div>' +
                //         '    <div style="border-bottom: #e6e6e6 1px solid; padding-bottom: 10px; line-height: 23px; font-size: 14px; padding-top: 10px"><strong style="line-height: 30px; color: #333333; font-size: 14px">问：礼品卡可以与优惠券一起使用吗？一次可以使用几张礼品卡？</strong><br>' +
                //         '    <span style="color: #717171">答：您好，礼品卡可以与优惠券一起使用，每笔订单可以使用多张礼品卡。</span></div>' +
                //         '    <div style="border-bottom: #e6e6e6 1px solid; padding-bottom: 10px; line-height: 23px; font-size: 14px; padding-top: 10px"><strong style="line-height: 30px; color: #333333; font-size: 14px">问：礼品卡用不完能不能退呀？ </strong><br>' +
                //         '    <span style="color: #717171">答：您好，礼品卡为不记名、不挂失、不兑现的产品，礼品卡售出后无法做退换操作。 </span></div>' +
                //         '    <div style="border-bottom: #e6e6e6 1px solid; padding-bottom: 10px; line-height: 23px; font-size: 14px; padding-top: 10px"><strong style="line-height: 30px; color: #333333; font-size: 14px">问：我的发票里怎么不是全部的商品金额？</strong><br>' +
                //         '    <span style="color: #717171">答：您好，因为礼品卡在销售时已开具发票给购买单位，所以在购买商品时礼品卡支付部分不再开具发票。</span></div>' +
                //         '    <div style="border-bottom: #e6e6e6 1px solid; padding-bottom: 10px; line-height: 23px; font-size: 14px; padding-top: 10px"><strong style="line-height: 30px; color: #333333; font-size: 14px">问：我购物时同时使用了礼品卡和银联卡一起合并付了款，退货的时候，钱可以一起退到礼品卡上吗？</strong><br>' +
                //         '    <span style="color: #717171">答：您好，发生退货时，需要整单全退，礼品卡支付部分将会退还礼品卡内，银联卡支付的部分退到相应银行卡账号中。 </span></div>' +
                //         '    <div style="border-bottom: #e6e6e6 1px solid; padding-bottom: 10px; line-height: 23px; font-size: 14px; padding-top: 10px"><strong style="line-height: 30px; color: #333333; font-size: 14px">问：用礼品卡购买商品有没有限制？</strong><br>' +
                //         '    <span style="color: #717171">答：您好，礼品卡在购买时没有最低消费金额等限制， 您只需在有效期内使用完。</span></div>' +
                //         '    <div style="border-bottom: #e6e6e6 1px solid; padding-bottom: 10px; line-height: 23px; font-size: 14px; padding-top: 10px"><strong style="line-height: 30px; color: #333333; font-size: 14px">问：商品配送免运费吗？</strong><br>' +
                //         '    <span style="color: #717171">答：云淘通购物单笔订单金额满99，免收快递费（特殊商品除外）。<br>' +
                //         '    具体的配送运费规则，请详见：<a class="helpcur" href="aB023.html">物流配送说明 </a></span></div>' +
                //         '    <div style="border-bottom: #e6e6e6 1px solid; padding-bottom: 10px; line-height: 23px; font-size: 14px; padding-top: 10px"><strong style="line-height: 30px; color: #333333; font-size: 14px">问：什么是享购云淘豆？</strong><br>' +
                //         '    <span style="color: #717171">答：具体的云淘豆说明请详见：<a class="helpcur" href="aB003.html">云淘豆说明 </a></span></div>' +
                //         '    <div style="border-bottom: #e6e6e6 1px solid; padding-bottom: 10px; line-height: 23px; font-size: 14px; padding-top: 10px"><strong style="line-height: 30px; color: #333333; font-size: 14px">问：在线支付的流程是什么样的？</strong><br>' +
                //         '    <span style="color: #717171">答：具体的购物流程请详见：<a class="helpcur" href="#/help?id=5&index=1">购物流程 </a></span></div></div>' +
                //         '    <div class="clear">'
                // },
                {
                    name: "用户注册", id: 2, html: '<div>' +
                        '        <div ><span style="padding-right: 10px; font-family: Arial; float: left; height: 100px; color: #006ab6; font-size: 18px">Step 1</span>' +
                        '        <div style="line-height: 23px; color: #333333; font-size: 14px; padding-top: 3px"><strong>点击页面右上方的"注册"链接注册云淘通账号。</strong></div>' +
                        '        </div>' +
                        '        <p style="padding-left: 60px; padding-top: 10px"><img src="imgUrl/api/Photo/Get/zcdj.png" width="349" height="129" alt=""></p>' +
                        '        <div>&nbsp;</div>' +
                        '        </div>' +
                        '        <div><span style="padding-right: 10px; font-family: Arial; float: left; height: 100px; color: #006ab6; font-size: 18px">Step 2</span>' +
                        '        <div style="line-height: 23px; color: #333333; font-size: 14px; font-weight: bolder; padding-top: 3px">请根据相应提示在信息栏内填入您的注册信息。</div>' +
                        '        </div>' +
                        '        <p style="padding-left: 60px; padding-top: 10px"><img src="imgUrl/api/Photo/Get/zctc.png" width="487" height="418" alt=""></p></div>'
                },
                {
                    name: "用户登陆", id: 3, html: '<div>' +
                        '        <div ><span style="padding-right: 10px; font-family: Arial; float: left; height: 100px; color: #006ab6; font-size: 18px">Step 1</span>' +
                        '        <div style="line-height: 23px; color: #333333; font-size: 14px; font-weight: bolder; padding-top: 3px">点击页面右上方的"登录"链接</div>' +
                        '        </div>' +
                        '        <p style="padding-left: 60px; padding-top: 10px"><img  src="imgUrl/api/Photo/Get/dldj.png" height="129" alt=""></p>' +
                        '        </div>' +
                        '        <p>&nbsp;</p>' +
                        '        <div><span style="padding-right: 10px; font-family: Arial; float: left; height: 100px; color: #006ab6; font-size: 18px">Step 2</span>' +
                        '        <div style="line-height: 23px; color: #333333; font-size: 14px; font-weight: bolder; padding-top: 3px">在登录页面的信息栏内填入对应信息，点击"登录"按钮进行登录，或通过选择登录按钮下方的合作网站账号进行快速登录。登录成功后，系统将自动跳转至云淘通首页。</div>' +
                        '        </div>' +
                        '        <p style="padding-left: 60px; padding-top: 10px"><img src="imgUrl/api/Photo/Get/dl.png" width="427" width="494" height="303" alt=""></p>'
                },
                {
                    name: "隐私政策", id: 4, html: '<style type="text/css">' +
                        '	    a.helpcur{ color:#036ab7;text-decoration:underline;}' +
                        '	    a.helpcur:hover{ color:#036ab7;text-decoration:none;}</style>' +
                        '<div style=" color: #666;">' +
                        '			您的信任对我们非常重要，我们深知个人信息对您的重要性，我们将按法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。有鉴于此，【北京云韬物联科技有限公司】（或简称“我们”）制定本《隐私政策》（下称“本政策”）并提醒您：' +
                        '			本政策适用于 云淘通购物网站 (以下简称 “云淘通”) 服务，请您务必仔细阅读并透彻理解本政策。本《隐私政策》中涉及的相关技术词汇，我们尽量使用简明扼要的表述， 以便您的理解。如果您不同意本《隐私政策》关于基本业务功能的相关内容，可能将影响为您提供相应 云淘通 的效果。一旦您开始使用我们的 云淘通，即意味着同意本政策。' +
                        '			您在下载、开启、浏览、注册、登录、使用 云淘通 软件时，我们可能会收集和使用您的相关信息。我们希望通过本《隐私政策》向您说明，我们将如何收集、使用、储存和分享这些信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。' +
                        '		</div>' +
                        '		<div style="border-bottom: #e6e6e6 1px solid; padding-bottom: 14px; line-height: 23px; font-size: 14px; padding-top: 14px">' +
                        '		<strong style="line-height: 30px; color: #333333; font-size: 14px">第一部分 定义</strong><br>' +
                        '		<span style="color: #717171">1、云淘通 提供者：是指研发并提供 云淘通购物网站 和服务法律主体，以下简称“经销商”或者“我们”。<br>' +
                        '		<span style="color: #717171">2、个人用户：是指注册 云淘通 账户的个人用户，以下称“您”。<br>' +
                        '		<span style="color: #717171">3、个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。<br>' +
                        '		<span style="color: #717171">4、个人敏感信息：是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。云淘通服务的个人敏感信息包括身份证件号码、手机号码、个人生物识别信息、银行账号、财产信息、交易信息、14岁以下（含）儿童的个人信息等（我们将在本隐私权政策中对具体个人敏感信息以粗体进行显著标识）。<br>' +
                        '		<span style="color: #717171">5、儿童：是指不满十四周岁的未成年人。<br>' +
                        '		<span style="color: #717171">6、个人信息删除：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。' +
                        '		除另有约定外，本政策所用定义与《 云淘通 注册协议》中的定义具有相同的含义。<br>' +
                        '		</span></span></span></span></span></span></div>' +
                        '		<div style="border-bottom: #e6e6e6 1px solid; padding-bottom: 14px; line-height: 23px; font-size: 14px; padding-top: 14px">' +
                        '			<strong style="line-height: 30px; color: #333333; font-size: 14px">第二部分 隐私政策</strong><br>' +
                        '			<strong>本《隐私政策》包含以下内容：</strong><br>' +
                        '			<span style="color: #717171">1.我们如何收集、使用您的个人信息<br>' +
                        '			<span style="color: #717171">2.我们如何使用Cookies和同类技术<br>' +
                        '			<span style="color: #717171">3.我们如何共享、转让、公开披露您的个人信息<br>' +
                        '			<span style="color: #717171">4.我们如何存储您的个人信息<br>' +
                        '			<span style="color: #717171">5.您的权利<br>' +
                        '			<span style="color: #717171">6.信息安全<br>' +
                        '			<span style="color: #717171">7.儿童个人信息保护<br>' +
                        '			<span style="color: #717171">8.您的个人信息如何在全球范围转移<br>' +
                        '			<span style="color: #717171">9.隐私政策的适用范围<br>' +
                        '			<span style="color: #717171">10.隐私政策的变更<br>' +
                        '		</span></span></span></span></span></span></span></span></span></span></div>' +
                        '		' +
                        '		<div style="border-bottom: #e6e6e6 1px solid; padding-bottom: 10px; line-height: 23px; font-size: 14px; padding-top: 10px"><strong style="line-height: 30px; color: #333333; font-size: 14px">1．我们如何收集、使用您的个人信息</strong><br>' +
                        '		<span style="color: red;margin-left: 30px;display: block;">为了保证用户注册、课程服务、在线课程等各项基本业务功能的正常运行， 我们会收集、使用下列与您有关的信息。如果您不提供相关信息，可能无法注册成为我们的用户或无法使用和享受我们提供的某些产品和服务，或者无法达到相关产品或服务拟达到的效果。</span><br>' +
                        '		<strong>（1）基本业务功能</strong><br>' +
                        '		<p style="margin-top: 20px;margin-left: 30px;">a)用户注册。要使用 云淘通 服务，您必须成为 云淘通 正式用户。当您注册 云淘通 账号或使用 云淘通 提供服务时， 您需要向我们提供您的手机号码及短信验证码。我们将通过短信验证码的方式核实您的身份信息的有效性。我们会将这些数据加密储存在安全的服务器中。</p>' +
                        '		<p style="margin-top: 20px;margin-left: 30px;">b)课程服务。为了给您提供完善的 云淘通 服务，云淘通 会要求您提供学员的昵称、年龄、称谓等信息，云淘通需要这些数据以便快速便利地为您提供相关课程服务，并不断优化服务内容以便更能符合您的使用需求。</p>' +
                        '		<p style="margin-top: 20px;margin-left: 30px;">c)在线课程。为您能顺利的完成在线课程的学习，您需在课前检测您设备的网络、摄像头、麦克风功能是否正常，如果您需要向讲师展示您的声音和影像，您需在设备向我们开启摄像头（相机）、麦克风功能的访问权限，您可使用这些功能与课程老师在线交流、视频互动。您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您亦可在设备中随时关闭相应访问权限，您关闭权限即代表您取消了这些授权，则我们将不再继收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能，如您继续使用这些功能您需重新开启访问权限。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。</p>' +
                        '		<p style="margin-top: 20px;margin-left: 30px;">d)地理位置信息：为了方便用户在预约课程时，您可以选择与您当前位置最近的门店或用户指定城市的指定门店进行上课，以及通过 云淘通 准确查询到门店位置及门店与您所在位置间的距离，寻找合适的交通路线，我们会收集您的位置信息，用于计算和显示距离您最近的门店。当您拒绝授权我们使用您的位置信息时，可以关闭位置的授权，我们将不再收集您的位置信息</p>' +
                        '		</div>' +
                        '		' +
                        '		<div style="border-bottom: #e6e6e6 1px solid; padding-bottom: 10px; line-height: 23px; font-size: 14px; padding-top: 10px"><strong style="line-height: 30px; color: #333333; font-size: 14px">2.我们如何使用Cookies和同类技术</strong><br>' +
                        '		<span style="color: red;margin-left: 30px;display: block;">我们或我们的第三方合作伙伴，可能通过cookies收集和使用您的信息，并将该等信息储存为日志信息。我们使用自己的cookies，目的是为您提供更个性化的 云淘通，并用于以下用途：</span><br>' +
                        '		<p style="margin-top: 20px;margin-left: 30px;">a)记住您的身份。cookies有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供的有关您的喜好或其他信息；</p>' +
                        '		<p style="margin-top: 20px;margin-bottom: 20px;margin-left: 30px;">b)分析您使用 云淘通 的情况。我们可利用cookies来了解您使用我们的云淘通进行什么活动，或哪些网页或课程服务最受您的欢迎；</p>' +
                        '		<span style="color: red;margin-left: 30px;display: block;">' +
                        '			我们为上述目的使用cookies的同时，可能将通过cookies收集的非个人身份信息，用于分析用户如何使用我们的私教课程。您可以通过浏览器设置拒绝或管理cookies。但请注意，如果停用cookies，您有可能无法享受最佳的服务体验，某些产私教课程也可能无法正常使用。' +
                        '		</span><br>' +
                        '		</div>' +
                        '		' +
                        '		<div style="border-bottom: #e6e6e6 1px solid; padding-bottom: 10px; line-height: 23px; font-size: 14px; padding-top: 10px"><strong style="line-height: 30px; color: #333333; font-size: 14px">3.我们如何共享、转让、公开披露您的个人信息</strong><br>' +
                        '		<strong>（1）共享</strong><br>' +
                        '			<span style="color: red;margin-left: 30px;display: block;">我们不会与本公司以外及本公司关联方以外的任何公司、组织和个人分享您的个人信息，除非获得您的明确同意。目前，我们会在以下情形中，向您征求您对共享个人信息的授权同意：</span><br>' +
                        '			<p style="margin-top: 20px;margin-left: 30px;">a)我们的服务商' +
                        '		我们可委任第三方服务提供者代表我们管理或操作若干功能或服务，而妥善执行该等功能或服务可能需要移转您所提交的信息。我们还可能基于为您提供服务向服务商基于必要合理原则提供您的个人信息，以完成或提升为您提供的服务。' +
                        '		</p>' +
                        '			<p style="margin-top: 20px;margin-left: 30px;">b)项目合作方' +
                        '		为了不断提升服务质量，我们仅在有限的情况下，向我们的项目合作方提供您的信息。' +
                        '		</p>' +
                        '			<p style="margin-top: 20px;margin-left: 30px;margin-bottom: 20px;">c)我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。对于共享您个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的要求、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。</p>' +
                        '			<strong>（2）转让：</strong><br>' +
                        '				<span style="color: red;margin-left: 30px;display: block;">我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</span><br>' +
                        '				<p style="margin-top: 20px;margin-left: 30px;margin-bottom: 20px;">a)在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息。' +
                        '			</p>' +
                        '			<strong>（3）公开披露：</strong><br>' +
                        '				<span style="color: red;margin-left: 30px;display: block;">我们仅会在以下情况下，公开披露您的个人信息：</span><br>' +
                        '				<p style="margin-top: 20px;margin-left: 30px;">a)获得您明确同意后或基于您的主动选择，我们可能会公开披露您的个人信息；' +
                        '			</p>' +
                        '			<p style="margin-top: 20px;margin-left: 30px;margin-bottom: 20px;">b)基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。' +
                        '			</p>' +
                        '			<strong>（4）共享、转让、公开披露个人信息时事先征得授权同意的例外情况：</strong><br>' +
                        '				<span style="color: red;margin-left: 30px;display: block;">以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</span><br>' +
                        '				<p style="margin-top: 20px;margin-left: 30px;">a)与国家安全、国防安全有关的；' +
                        '			</p>' +
                        '			<p style="margin-top: 20px;margin-left: 30px;">b)与公共安全、公共卫生、重大公共利益有关的；' +
                        '			</p>' +
                        '			' +
                        '			<p style="margin-top: 20px;margin-left: 30px;">c)与犯罪侦查、起诉、审判和判决执行等有关的；' +
                        '			</p>' +
                        '			<p style="margin-top: 20px;margin-left: 30px;">d)出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；' +
                        '			</p>' +
                        '			<p style="margin-top: 20px;margin-left: 30px;">e)您自行向社会公众公开的个人信息；' +
                        '			</p>' +
                        '			<p style="margin-top: 20px;margin-left: 30px;">f)从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。' +
                        '			</p>' +
                        '		</div>' +
                        '		' +
                        '		' +
                        '		<div style="border-bottom: #e6e6e6 1px solid; padding-bottom: 10px; line-height: 23px; font-size: 14px; padding-top: 10px"><strong style="line-height: 30px; color: #333333; font-size: 14px">4.我们如何存储您的个人信息</strong><br>' +
                        '			<span style="color: red;margin-left: 30px;display: block;">您的个人信息将在实现业务功能必要目的所需的最短时间内，通常为您开始使用至停止使用 云淘通 服务期间内存储，超过该等保存期限，将对个人信息进行删除或者匿名化处理。我们在中华人民共和国境内运营中收集和产生的个人信息，原则上存储在中国境内，以下情形除外：</span><br>' +
                        '			<p style="margin-top: 20px;margin-left: 30px;">a)法律法规有明确规定；</p>' +
                        '			<p style="margin-top: 20px;margin-left: 30px;margin-bottom: 20px;">b)获得您的明确授权</p>' +
                        '			<span style="color: red;margin-left: 30px;display: block;">针对以上情形，我们会确保依据法律法规和本《隐私政策》对您的个人信息提供安全保护。</span><br>' +
                        '		</div>' +
                        '		' +
                        '		<div style="border-bottom: #e6e6e6 1px solid; padding-bottom: 10px; line-height: 23px; font-size: 14px; padding-top: 10px"><strong style="line-height: 30px; color: #333333; font-size: 14px">5.您的权利</strong><br>' +
                        '			<strong>（1）您对于自己的个人信息享有的权利</strong><br>' +
                        '			<span style="color: red;margin-left: 30px;display: block;">按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</span><br>' +
                        '			<p style="margin-top: 20px;margin-left: 30px;">a)访问您的个人信息</p>' +
                        '			<span style="margin-left: 30px;display: block;">您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过登录自己的账户查看或修改账户设置和信息。</span><br>' +
                        '			<p style="margin-top: 20px;margin-left: 30px;margin-bottom: 20px;">b)更正或删除您的个人信息</p>' +
                        '			<span style="margin-left: 30px;display: block;">当您发现我们处理的关于您的个人信息有错误时，您有权要求我们作出更正。</span><br>' +
                        '			<span style="margin-left: 30px;display: block;">如果我们处理个人信息的行为违反法律法规；</span><br>' +
                        '			<span style="margin-left: 30px;display: block;">如果我们收集、使用您的个人信息，却未征得您的同意；</span><br>' +
                        '			<span style="margin-left: 30px;display: block;">如果我们处理个人信息的行为违反了与您的约定；</span><br>' +
                        '			<span style="margin-left: 30px;display: block;">如果您不再使用我们的产品或服务；</span><br>' +
                        '			<span style="margin-left: 30px;display: block;">如果您不再使用我们的产品或服务；</span><br>' +
                        '			<span style="margin-left: 30px;display: block;">如果您不再使用我们的产品或服务；</span><br>' +
                        '			<span style="margin-left: 30px;display: block;">如果我们终止了产品或服务。</span><br>' +
                        '			<span style="margin-left: 30px;display: block;">您可以通过 云淘通 软件提出您的删除请求。若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。 当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。</span><br>' +
                        '			<p style="margin-top: 20px;margin-left: 30px;margin-bottom: 20px;">d)改变您授权同意的范围</p>' +
                        '			<span style="margin-left: 30px;display: block;">每个基本业务功能需要一些基本的个人信息才能得以完成。对于为了实现业务功能之目的而收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意，您可以通过设备系统权限设置对于您的授权进行管理。当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。</span><br>' +
                        '			<strong>（2）无法响应您的请求的情形</strong><br>' +
                        '			<span style="color: red;margin-left: 30px;display: block;">在以下情形中，我们将无法响应您的请求：</span><br>' +
                        '			<p style="margin-top: 20px;margin-left: 30px;margin-bottom: 20px;">a)与个人信息控制者履行法律法规规定的义务相关的；</p>' +
                        '			<p style="margin-top: 20px;margin-left: 30px;margin-bottom: 20px;">b)与国家安全、国防安全直接相关的；</p>' +
                        '			<p style="margin-top: 20px;margin-left: 30px;margin-bottom: 20px;">c)与公共安全、公共卫生、重大公共利益直接相关的；</p>' +
                        '			<p style="margin-top: 20px;margin-left: 30px;margin-bottom: 20px;">d)犯罪侦查、起诉、审判和执行判决等直接相关的；</p>' +
                        '			<p style="margin-top: 20px;margin-left: 30px;margin-bottom: 20px;">e)个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>' +
                        '			<p style="margin-top: 20px;margin-left: 30px;margin-bottom: 20px;">f)为了维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的</p>' +
                        '			<p style="margin-top: 20px;margin-left: 30px;margin-bottom: 20px;">g)响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；</p>' +
                        '			<p style="margin-top: 20px;margin-left: 30px;margin-bottom: 20px;">h)涉及商业秘密的。</p>' +
                        '		</div>' +
                        '		' +
                        '		<div style="border-bottom: #e6e6e6 1px solid; padding-bottom: 10px; line-height: 23px; font-size: 14px; padding-top: 10px"><strong style="line-height: 30px; color: #333333; font-size: 14px">6.信息安全</strong><br>' +
                        '			<span style="margin-left: 30px;display: block;">我们仅在为本《隐私政策》所述目的和法律法规允许的时限内保留您的个人信息。</span><br>' +
                        '			<span style="margin-left: 30px;display: block;">为了保障您的信息安全，经销商公司会在现有技术水平下采取合理必要的措施来保护您的信息，采取物理、技术和行政管理安全措施来降低丢失、误用、 非授权访问、披露和更改的风险，包括但不限于传输层数据加密、防火墙和加密存储、物理访问控制以及信息访问授权控制。为此我们设置了安全程序保护您的信息不会被未经授权的访问所窃取。</span><br>' +
                        '			<span style="margin-left: 30px;display: block;">在登录时，云淘通也坚持使用密码，避免您的个人资料档案等在未经授权的状况下被存取。</span><br>' +
                        '			<span style="margin-left: 30px;display: block;">您在使用 云淘通 服务时，请妥善保管好您的 云淘通 账号及其密码，我们会通过您的账号及其密码来识别您的身份。一旦您泄漏了 云淘通 私教账号及密码，您可能会丢失您的信息，并可能产生对您不利的法律后果。如您发现 云淘通 私教账号及其密码因任何原因已经或将受到泄漏时，请您立即联络我们，以便我们采取相应措施。但在我们知悉此种情况和在合理时间内采取行动前，我们对此不负任何责任。</span><br>' +
                        '			<span style="margin-left: 30px;display: block;">在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，及时上报个人信息安全事件的处置情况。</span><br>' +
                        '		</div>' +
                        '		' +
                        '		<div style="border-bottom: #e6e6e6 1px solid; padding-bottom: 10px; line-height: 23px; font-size: 14px; padding-top: 10px"><strong style="line-height: 30px; color: #333333; font-size: 14px">7.儿童个人信息保护</strong><br>' +
                        '			<span style="color: red;margin-left: 30px;display: block;">我们了解，我们所收集、处理的学员个人信息可能来自14周岁以下未成年人（“儿童”），我们非常重视对儿童个人信息的保护。对此，我们严格按照《中华人民共和国网络安全法》、《儿童个人信息网络保护规定》等国内个人信息保护的相关规定，落实保护责任，作出以下承诺：</span><br>' +
                        '			<span style="margin-left: 30px;display: block;">我们不收集儿童个人信息，如儿童需要使用 云淘通 服务，请监护人进行注册和课程预约，并在课程过程中全程陪同并对儿童的安全负责。</span><br>' +
                        '		</div>' +
                        '		' +
                        '		<div style="border-bottom: #e6e6e6 1px solid; padding-bottom: 10px; line-height: 23px; font-size: 14px; padding-top: 10px"><strong style="line-height: 30px; color: #333333; font-size: 14px">8.您的个人信息如何在全球范围转移</strong><br>' +
                        '			<span style="margin-left: 30px;display: block;">原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内，仅在获得您授权同意（如为儿童，则会征得监护人的授权 同意）的基础上向境外转移。</span><br>' +
                        '		</div>' +
                        '		' +
                        '		<div style="border-bottom: #e6e6e6 1px solid; padding-bottom: 10px; line-height: 23px; font-size: 14px; padding-top: 10px"><strong style="line-height: 30px; color: #333333; font-size: 14px">9.隐私政策的适用范围</strong><br>' +
                        '			<span style="margin-left: 30px;display: block;">我们所有的云淘通均适用本《隐私政策》。未经您明确同意我们不会限制您按照本隐私政策所应享有的权利。</span><br>' +
                        '			<span style="margin-left: 30px;display: block;">请您注意，本《隐私政策》仅适用于我们所收集、保存、使用、共享、披露信息。</span><br>' +
                        '		</div>' +
                        '		' +
                        '		<div style="border-bottom: #e6e6e6 1px solid; padding-bottom: 10px; line-height: 23px; font-size: 14px; padding-top: 10px"><strong style="line-height: 30px; color: #333333; font-size: 14px">10.隐私政策的变更</strong><br>' +
                        '			<span style="margin-left: 30px;display: block;">我们可能适时修订本《隐私政策》的条款，该等修订构成本《隐私政策》的一部分。在该种情况下，若您继续使用我们的云淘通，即表示同意受经修订的本《隐私政策》的约束。</span><br>' +
                        '		</div>'
                }
            ]
        },
        {
            title: "购物指南",
            menusItem: [
                {
                    name: "购物流程", id: 5, html: '<style type="text/css">' +
                        '        h2{ font-size:14px;color: #006ab6;' +
                        '        }' +
                        '        .step {' +
                        '        font-family: Arial;' +
                        '        font-size: 18px;' +
                        '        color: #006ab6;' +
                        '        padding-right:5px;' +
                        '        }</style>' +
                        '        <p><img src="jrgwc.png" alt=""></p>' +
                        '        <br>' +
                        '        <span class="step">Step 1：</span><strong><span style="font-size: 14px">浏览要购买的商品，点击“加入购物车”商品会自动添加到购物车里。<br>' +
                        '        <br>' +
                        '        <img src="imgUrl/api/Photo/Get/jrgwc.png" width="878" height="433" alt=""></span></strong>&nbsp;<br>' +
                        '        <br>' +
                        '        <span class="step">Step 2：</span><strong><span style="font-size: 14px">直接点击“去购物车结算”或点击上部区域的“购物车“，查看购物车内商品，点击去结账。</span></strong><br>' +
                        '        <p style="padding-left: 60px; padding-top: 5px"><img src="imgUrl/api/Photo/Get/gwc.png" width="800" alt=""></p>' +
                        '        <br>' +
                        '        <br>' +
                        '        <span class="step">Step 3：</span><strong><span style="font-size: 14px">进入“我的购物车”，核对所购商品的数量/型号/金额等信息</span></strong><strong><span style="font-size: 14px">无误后点击“去结算”<br>' +
                        '        <br>' +
                        '        <img src="imgUrl/api/Photo/Get/qjs.png" width="700" height="245" alt=""><br>' +
                        '        </span></strong><span style="padding-left: 52px"><br>' +
                        '        </span><span class="step">Step 4：</span><strong><span style="font-size: 14px">进入订单结算页面，依次填写收货人信息、配送方式、发票信息、是否用礼品卡或优惠券抵消订单金额，在确认无误后点击“提交订单”。</span></strong><br>' +
                        '        <br>' +
                        '        <img src="imgUrl/api/Photo/Get/tjdd.png" width="800" alt="">&nbsp;<br>' +
                        '        <br>' +
                        '        <span class="step">Step 5：</span><strong><span style="font-size: 14px">订单提交后网页会弹出支付窗口，请确认支付</span></strong><span style="font-size: 12px;">（暂时只支持云淘豆支付）</span><strong><span style="font-size: 14px">，然后结束购物过程。<br>' +
                        '        <br>' +
                        '        <img src="imgUrl/api/Photo/Get/fk.png" width="700" height="305" alt=""><br>'
                },
                {
                    name: "订单状态", id: 6, html: '<div style="line-height: 23px; color: #3d3d3d; font-size: 14px">在“个人中心/订单中心/商品订单”中您可以查看到您订单的状态：<br>' +
                        '        <div style="color: #333333; font-size: 14px; font-weight: bold; padding-top: 5px">1、待兑换</div>' +
                        '        您的订单提交后，订单状态显示为待兑换，如30分钟内未付款我们会取消该笔订单。<br>' +
                        '        <div style="color: #333333; font-size: 14px; font-weight: bold; padding-top: 5px">2、待发货</div>' +
                        '        您的订单等待发货。<br>' +
                        '        <div style="color: #333333; font-size: 14px; font-weight: bold; padding-top: 5px">3、待收货</div>' +
                        '        您订购的商品已发出，请您注意接听物流电话收货。<br>' +
                        '        <div style="color: #333333; font-size: 14px; font-weight: bold; padding-top: 5px">4、已收货</div>' +
                        '        订单已成功签收。<br>' +
                        '        <div style="color: #333333; font-size: 14px; font-weight: bold; padding-top: 5px">5、已取消</div>' +
                        '        订单30分钟内未付款或者您主动在商品详情内点击取消订单按钮主动取消的订单<br>' +
                        '        </div>'
                },
                { name: "快递查询", id: 7, linkUrl: 'http://www.kuaidi100.com/?from=openv' },
            ]
        },
        {
            title: "支付充值",
            menusItem: [
                {
                    name: "充值云淘豆", id: 8, html: '<div><span style="font-size: 14px">目前为您提供的充值云淘豆的支付方式有：支付宝、微信。即时到帐，安全准确，推荐您在结账时使用。</span><span style="font-size: 14px"><span style="line-height: 18px; font-family: Simsun, Helvetica, sans-serif; color: rgb(51,51,51)"><br>' +
                        '        <br>' +
                        '        </span></span></div>' +
                        '        <span style="font-size: 14px">在您登录云淘通后，点击右上角‘个人中心/淘豆中心/充值云淘豆’输入充值金额，确认后选择充值方式，点击‘确认充值’扫码完成充值。<br>' +
                        '        </span><br>' +
                        '        <br>' +
                        '        <br>' +
                        '        <div><span style="color: rgb(128,128,128)">温馨提醒：请在三十分钟内完成充值以免充值失败；充值过程中请勿退出充值页面或关闭支付弹窗，以免云淘豆未能及时到账；如若云淘豆未能及时到账，请联系客服。</span></div>'
                },
                // { name: "优惠券回收", id: 9, html: '<div><span style="font-size: 14px">在您登录云淘通后，点击右上角‘个人中心/充值中心/卡券回收’，进入页面后按照页面提示完成卡券回收</span><br><span style="color: rgb(128,128,128)">温馨提醒：如若云淘豆未能及时到账，请联系客服。</span></div>' },
                // { name: "云淘豆购买", id: 10 },
            ]
        },
        {
            title: "配送方式",
            menusItem: [
                {
                    name: "配送方式", id: 11, html: '<style type="text/css">' +
                        '        span a{ color:#4275da; text-decoration: underline;}' +
                        '        span a:hover{color:#4275da; text-decoration: none;}</style>' +
                        '        <p><span style="color: #000000"><strong><span style="font-size: 18px">云淘为您提供中国大陆地区全覆盖的快递配送服务</span></strong></span><span style="font-size: 18px"><br>' +
                        '        </span><strong><span style="font-size: 18px"><br>' +
                        '        如何查询您的包裹<br>' +
                        '        </span></strong><br>' +
                        '        如果您已成功订购云淘通的智选商品，订单状态变为“已发货”后，您可以登录：个人中心—→订单中心—→订单详情查看订单，点击查询“查看物流”即可查询到您所购买的商品的在途情况。<br>' +
                        '        或请您点击对应的物流承运商网站进行查询，快递单号可以登录云淘通账号的订单管理中获取，建议发货后24小时后进行查询。查询链接如下：<br>' +
                        '        <br>' +
                        '        <strong>邮政EMS&nbsp;<br>' +
                        '        <br>' +
                        '        </strong>服务热线：11185 <br>' +
                        '        官网地址：<span><a href="http://www.ems.com.cn/">http://www.ems.com.cn/</a></span><br>' +
                        '        <br>' +
                        '        <strong>顺丰快递：</strong> <br>' +
                        '        <br>' +
                        '        服务热线：4008-111111 <br>' +
                        '        官网地址：<span><a href="http://www.sf-express.com/cn/sc/">http://www.sf-express.com/cn/sc/</a></span><br>' +
                        '        <br>' +
                        '        <strong>宅 急 送：</strong> <br>' +
                        '        <br>' +
                        '        服务热线：400-678-9000 <br>' +
                        '        官网地址：<span><a href="http://www.zjs.com.cn/">http://www.zjs.com.cn/</a></span><br>' +
                        '        <br>' +
                        '        &nbsp;</p>'
                },
                {
                    name: "配送政策", id: 12, html: '<br>' +
                        '        <span style="font-size: 18px"><strong>1.&nbsp; 关于配送运费：</strong></span><br>' +
                        '        <br>' +
                        '        1） 云淘通购物单笔订单金额低于168元人民币，根据配送区域的不同，按区域和订单重量收取运费。详情参考下表：<br>' +
                        '        <br>' +
                        '        <style type="text/css">' +
                        '        table{ border-top:1px solid #999;border-left:1px solid #999; width:650px;}' +
                        '        td{ padding:5px 12px;border-bottom:1px solid #999;border-right:1px solid #999;line-height:18px; font-size:12px;}' +
                        '        table td span{ text-align:right; float:left; width:60px; padding-right:12px;}</style>' +
                        '        <table border="0" cellspacing="0" cellpadding="0" width="650">' +
                        '        <tbody>' +
                        '        <tr>' +
                        '        <td><strong>收货地区</strong></td>' +
                        '        <td><strong>起重（1千克）</strong></td>' +
                        '        <td><strong>续重（每0.5千克）</strong></td>' +
                        '        </tr>' +
                        '        <tr>' +
                        '        <td>北京，河北，天津</td>' +
                        '        <td>10元</td>' +
                        '        <td>1元</td>' +
                        '        </tr>' +
                        '        <tr>' +
                        '        <td>山东，河南，山西，浙江，江苏，上海，安徽，辽宁</td>' +
                        '        <td>15元</td>' +
                        '        <td>5元</td>' +
                        '        </tr>' +
                        '        <tr>' +
                        '        <td>福建，湖北，湖南，江西，四川，重庆，黑龙江，吉林，甘肃，内蒙，陕西，广东，贵州，云南，广西，海南，宁夏，青海</td>' +
                        '        <td>20元</td>' +
                        '        <td>6元</td>' +
                        '        </tr>' +
                        '        <tr>' +
                        '        <td>新疆，西藏</td>' +
                        '        <td>24元</td>' +
                        '        <td>8元</td>' +
                        '        </tr>' +
                        '        </tbody>' +
                        '        </table>' +
                        '        <p>2） 云淘通购物单笔订单金额满168元人民币，免费配送。<br>' +
                        '        </p>' +
                        '        <p></p>' +
                        '        <span style="font-size: 18px"><strong>2. 关于退换货配送运费 </strong></span><br>' +
                        '        <br>' +
                        '        1）如果您购买的商品因我司原因造成退换货，将由我司承担商品寄回享购的运费，<br>' +
                        '        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 上限为人民币25元（大型家电产品除外）；168元以下订单已支付的运费将全额返还。<br>' +
                        '        <br>' +
                        '        2）如果您购买的商品因个人原因办理退换货，商品寄回享购运费将由您个人承担；<br>' +
                        '        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;168元以下订单已支付的运费将不予退还。（部分类别商品部提供个人原因退换货，<br>' +
                        '        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;详见退换货政策）<br>' +
                        '        <br>' +
                        '        3）换货时商品第二次配送运费统一由我司承担，满足退换货政策的前提下，<br>' +
                        '        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;每张订单只提供一次因您个人原因产生的免费换。'
                },
            ]
        },
        {
            title: "售后服务",
            menusItem: [
                {
                    name: "退/换货政策", id: 13, html: '<style type="text/css">' +
                        '        h2{' +
                        '        font-size:14px;' +
                        '        color:#036ab7;' +
                        '        padding-top:5px;' +
                        '        padding-bottom:5px;' +
                        '        }' +
                        '        .title1{font-size:14px; color:#333333; font-weight:bold; line-height:25px;}' +
                        '        .title2{color:#333333; line-height:23px;padding-bottom:10px;}</style>' +
                        '        <h2>一、电子数码产品退换货政策</h2>' +
                        '        <div class="title1">符合办理退换货的条件（质量问题）<br>' +
                        '        &nbsp;</div>' +
                        '        <div class="title2">1. 商品出现国家《三包》法规中所规定的非人为质量问题；<br>' +
                        '        2. 在7日内（退、换货）或15日内（换货）至生产厂商的售后服务中心开具证明商品有质量问题的检测报告（如售后服务中心无法提供检测报告，则需提供商品检测或故障单）；<br>' +
                        '        3. 商品外包装完整未损坏，商品及其标配配件、赠品（如购买时有赠品）、说明书、保修凭证和发票均齐全（如申请退换货的商品为成套商品中的一个商品如礼盒、礼包，则需退回此整套商品）；<br>' +
                        '        4. 退换货时效定义：<br>' +
                        '        &nbsp;&nbsp;&nbsp;&nbsp;退货时效：自客户收到商品起7日内开具证明商品有质量问题的检测报告；<br>' +
                        '        &nbsp;&nbsp;&nbsp;&nbsp;换货时效：自客户收到商品起15日内开具证明商品有质量问题的检测报告；<br>' +
                        '        5. 运费说明（非上门办理）：<br>' +
                        '        &nbsp;&nbsp;&nbsp;&nbsp;符合以上要求的退换货办理，如顾客未选择门店上门自助办理，需寄回发货库房，需采用快递的方式，不支持到付，经确认因商品质量问题，ISGO负责报销不超过25元的快递运费，以账户余额的形式充值到顾客账号；</div>' +
                        '        <div class="title1">非质量问题符合办理退货的条件<br>' +
                        '        &nbsp;</div>' +
                        '        <div class="title2">如商品无质量问题并符合以下条件，自客户收到商品起7日内可申请退货：<br>' +
                        '        1. 商品未拆封使用，保持出售时的原质原样，不影响二次销售； 2. 商品外包装完整未损坏，商品及其标配配件、赠品（如购买时有赠品）、说明书、保修凭证和发票均齐全（如申请退货的商品为成套商品中的一个商品如礼盒、礼包等，则需退回此整套商品）； 3. 下列商品不适用无质量问题退货： a. 移动硬盘、显示器、台式机电脑、笔记本电脑、平板电脑、单反相机、数码相机、单反/数码相机镜头、数码摄像机、手机； b. 办公设备（包括但不限于打印机、扫描仪、一体机、传真机、投影机等）、打印耗材（包括但不限于墨盒、硒鼓、打印纸等）、闪存类商品（U盘、存储卡等）、光存储附件及耗材、电池等； 4. 运费说明：因无质量问题退货而产生的所有运费由客户自行承担；</div>' +
                        '        <div class="title2">以下情况ISGO不提供办理退换货服务 1. 任何非由ISGO出售的商品； 2. 超过国家《三包》法规中规定的退换货的有效期； 3. 商品已使用（有质量问题除外）或为正常使用磨损； 4. 商品外包装不完整或损坏，标配配件、赠品（如购买时有赠品）或说明书不完整，保修凭证和发票任一缺失或涂改，保修凭证上的的商品型号或序列号与商品实物不符； 5. 擅自撕毁或涂改商品标贴、序列号、防伪标记； 6. 未经授权的疏忽、误用、滥用、拆装、碰撞、进液、事故、改动、不正确的安装或保管而导致的商品质量问题； 7. 由非生产厂商指定或授权维修点进行拆装或维修而导致的商品质量问题；</div>' +
                        '        <h2>二、食品退换货政策</h2>' +
                        '        <div class="title1">总则</div>' +
                        '        <div class="title2">1、依据《中华人民共和国产品质量法》、《中华人民共和国消费者权益保护法》等法律法规，云淘通承诺对已销售的存在质量问题的商品办理退换货；<br>' +
                        '        2、您签收商品时请按照云淘通验货签收与拒收之要求进行验货，检查无误后再行签收。签收后，云淘通不再为验货时即可发现的问题承担售后责任；<br>' +
                        '        3、请保管好需办理售后业务的商品及其包装、票据和赠品，作为办理相关业务的依据，若发生退换货时，需事先与云淘通客服中心取得确认。<br>' +
                        '        &nbsp;</div>' +
                        '        <div class="title1">退换货服务政策</div>' +
                        '        <div class="title2">1、为保障您的权益，请您与客户代表当面核对商品的种类、数量、规格、赠品、金额等是否与您的订单一致，商品是否存在破损、渗漏等情况，确认无误后再进行签收。若商品存在任何质量问题或单货不符，请当场拒收整个包裹，云淘通将为您办理退款服务。<br>' +
                        '        2、食品类商品签收后非质量问题不办理退换货，若签收后7日内您发现验货时无法发现的质量问题，请致电客服中心，并提交相关食品检验部门的质检证明，云淘通客服将为您处理；<br>' +
                        '        3、如赠品出现质量问题，可以进行换货，不可退货。请致电客服中心，云淘通客服将为您处理；<br>' +
                        '        4、目前退换货为整个订单，不支持部分退换货；<br>' +
                        '        5、申请办理退货时，请将发票随商品一同返还，若发票丢失，则无法办理退货；<br>' +
                        '        6、发生退货时，购买商品所赠送的云淘豆，云淘通将在退款时同步从您的云淘豆账户里扣除；<br>' +
                        '        7、商品图片仅供参照，一切以实物为准。因拍摄灯光及不同显示器等问题造成的色差不属于质量问题。<br>' +
                        '        <br>' +
                        '        以下情况不予办理退换货：<br>' +
                        '        1、任何非云淘通出售的商品；<br>' +
                        '        2、换货商品只能针对同一商品、同一价位的更换，不支持商品间换货及差价换货；<br>' +
                        '        3、任何已使用过的商品，有质量问题的除外；<br>' +
                        '        4、任何因您个人原因导致超过保质期的商品；<br>' +
                        '        5、未经授权的误用、疏忽、滥用、事故、改动、不正确的使用方法所造成的商品质量问题，或撕毁、涂改标贴、防伪标记的商品不予退换货；<br>' +
                        '        6、任何因非正常使用及保管导致出现质量问题的商品。</div>' +
                        '        <h2>三、奢侈品退换货政策</h2>' +
                        '        <div class="title1">符合退换货要求：</div>' +
                        '        <div class="title2">（1）因质量问题，自商品售出之日（以商品实际签收日期为准）起7日内提出退换货请求，并出具产品质量检测报告；<br>' +
                        '        （2）商品未经使用，品相全新，原质原貌；<br>' +
                        '        （3）商品包装完好无损，品牌原包装及防损吊牌未损坏、变形、拆除；<br>' +
                        '        （4）商品所有附属配件资料（如产品标识、包装、吊牌、三包卡、说明书、发票、赠品、配件等）齐全。<br>' +
                        '        （5）对于套装商品或者组合销售商品，无法为您办理部分商品退货。<br>' +
                        '        （6）限量版或其他稀缺商品，可能无法更换，因质量问题，可以为您退货。<br>' +
                        '        &nbsp;</div>' +
                        '        <div class="title1">售后维修服务</div>' +
                        '        <div class="title2">（1）如客户需要对商品进行维修，根据不同商品的具体情况，云淘通可协助客户联系相关维修服务提供商。<br>' +
                        '        （2）如客户收货后对商品质量表示质疑，云淘通有权保留鉴定权，核实后合理解决相关事宜。<br>' +
                        '        （3）网站上商品的图片因拍摄灯光及不同显示器色差等问题可能造成商品图片与实物有一定色差，不属质量问题，对此请您予以理解。<br>' +
                        '        &nbsp;</div>' +
                        '        <div class="title1">商品如若出现以下情况，则视为非原质原貌商品</div>' +
                        '        <div class="title2">（1）商品防损吊牌出现变形、破损、断裂等与出库原貌不同的；<br>' +
                        '        （2）商品产品标识、包装、吊牌、三包卡、说明书、发票、赠品、配件等缺失的；<br>' +
                        '        （3）商品原厂密封包装或封签被拆封、移位、撕毁的；<br>' +
                        '        （4）商品任何因不当使用及未妥善保管而导致的质量问题的；<br>' +
                        '        （5）商品经穿、戴、使用、洗涤、遭污损、损坏等情形的；<br>' +
                        '        （6）被使用或人为因素磨损的商品；<br>' +
                        '        （7）手表表带经过调整；<br>' +
                        '        （8）密封商品原包装被打开的；<br>' +
                        '        （9）钻石、黄金、手表、珠宝首饰、贴身衣物类、高端家纺等特殊商品一经售出；<br>' +
                        '        （10）高尔夫球杆、帐篷、自行车一经使用；<br>' +
                        '        &nbsp;</div>' +
                        '        &nbsp;&nbsp;&nbsp;&nbsp;商品退回云淘通时请您使用塑料袋将品牌原包装包好，不要让快递公司将快递单和粘胶直接粘在商品的品牌原包装上（特别是商品品牌原包装盒表面），否则我们不予退换，请您理解。如您在购买奢侈品过程中有任何问题，请拨打客服热线联系我们（服务时间：周一到周五 9:00-18:00）。 <br>' +
                        '        <br>' +
                        '        <span style="color: #ff0000">温馨提示：</span><br>' +
                        '        在退换货过程中，如您未经云淘通审核通过即将商品退回云淘通的，与商品有关的毁损、灭失、被盗等一切风险由您自行承担，云淘通不提供商品保管、重发、退款等服务，对于你邮寄的邮件或包裹等物品的签收不视为我们对您退回商品负有任何义务，必要时云淘通有权处置您擅自退回的商品。'
                },
                { name: "退/换货流程", id: 14, html: '<img alt="" width="680" height="227" src="imgUrl/api/Photo/Get/th.jpg">' },
            ]
        },
        {
            title: "个人中心",
            leftDisplay:true,
            menusItem: [
                {
                    name: '我的订单',
                    linkUrl: '#/order/index'
                },
                // {
                //     name: '卡券回收',
                //     linkUrl: '#/user/exchange'
                // }
            ]
        },
        {
            title: "帮助中心",
            toolDisplay:true,//底部隐藏
            menusItem: [
                {
                    name: '违规订单处理规则',
                    id: 15, 
                    html: '<div class="Section0" style="layout-grid:15.6000pt;"><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">为了维护以生活消费为目的的用户及平台销售</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">供应链</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">商家（以下简称</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">供应链</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">）的权益，若用户出现本规则中所述的违规订单情形，用户承诺其自愿接受</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">或商家按照本规则进行处理。</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;'+
                    'font-style:normal;font-size:7.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">一、违规订单的情形包括但不限于以下行为：</font></span></b><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">1.一人或多人合意使用一个或多个</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">账号下单不支付、不完全支付或者选择延迟发货等方式违规占库存；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">2.</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">供应链</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">刷单炒信或帮助</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">供应链</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">刷单炒信的下单行为；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">3.商品或服务的供应商、代理商、销售商等对其所供货或销售的商品、服务进行回购的行为；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">4.代购、中间商、零售商或批发商等人员或群体、公司等，以再销售或商业使用为目的的订购行为；（企业用户、或与</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">特殊销售渠道商签订合同的除外）</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">5.盗取他人</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">账号、银行账号等信息、或盗取他人</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">账号中的资产等的下单行为；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">6.出现威胁</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">或</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">供应链</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">系统安全的行为；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">7.出现违反用户注册协议中约定、</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">平台规则规定的行为；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">8.使用注册的</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">账号，或注册信息、订单详情中含任何虚假信息的</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">账号，或者以任何非</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">认可的方式（如：机器人软件、蜘蛛软件、爬虫软件、刷屏软件等）访问或登录</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通积分</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">商城网站进行包括但不仅限于如下行为：</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>1）领取、兑换优惠券；</span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>2）进行抽奖；</span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>3）领取</span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘豆</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>4）</span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">兑换</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">商品；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>5）参与各类优惠活动；</span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">9.一人或多人合意使用一个或多个</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">账号购买</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">兑换</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">商品或服务等数量超过正常生活消费需求的订购行为；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">10.下单后拒不收货的行为，包含但不仅限于以下情形：</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>1）</span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">平台商品不支持</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">使用货到付支付</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">积分</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">方式，同一用户的相同或不同</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">账号累计出现</font>3次以上无法联系到客户，或在付款前无正当理由拒收的；</span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>2）收货人声称非本人订购的订单，</span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">判定为虚假下单或恶意代下单的情形；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">11.</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">兑换</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">大批量商品，以资金紧张或其他理由要求分批送货；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">12.一人或多人合意使用一个或多个京东账号购买商品或服务等，要求更改收货地址、收货人的，单次或累计更改订单商品总数量大于等于50件，或30天内要求更改次数大于等于3次，或者要求转交其他承运商进行二次配送的；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">13.利用软件、技术手段、拆单或其他方式，为套取优惠、折扣、赠品、运费或其他利益而注册一个或多个</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">账号、一人使用同一或多个</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">账号，或合意他人使用同一或多个</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">账号进行下单等行为；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">14.敲诈勒索</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">供应链</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">商家，且商家可提供聊天记录或录音等证据；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">15.通过订单收货信息、订单备注、留言、评价等手段发送违规内容、广告或垃圾信息等；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">16、下单时收货地址出现两个及以上省或市的订单行为，例如</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">北京市海淀区</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">XX区XX街道新疆维吾尔自治区哈密市XX</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">17.为获取不正当利益而利用</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">网站、系统故障或缺陷或漏洞进行下单的行为。</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;'+
                    'font-style:normal;font-size:7.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">二、若出现以上第一条中的任一情形，用户承诺自愿接受</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;'+
                    'font-style:normal;font-size:7.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;'+
                    'font-style:normal;font-size:7.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">或</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;'+
                    'font-style:normal;font-size:7.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">供应链</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;'+
                    'font-style:normal;font-size:7.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">商家对违规订单进行以下任一项或多项操作：</font></span></b><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">1.提交的订单系统不予通过；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">2.取消订单；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">3.不发货；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">4.对已出库、发货的订单进行拦截、追回；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">5.限制、降低</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">会员权益，等；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">6.扣除</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">豆；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">7.使用以上第一条中的形式获得的京豆、优惠券、赠品、E卡或者已经发放的实物奖品等，京东或商家有权回收、作废、或禁止、限制其使用；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">8.删除或屏蔽违规文字、图片或信息；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">9.暂停或停止服务；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">10.其他</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">或</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">供应链</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">商家认为有必要的管控措施。</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;'+
                    'font-style:normal;font-size:7.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">三、注意事项</font></span></b><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;'+
                    'font-style:normal;font-size:7.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">1.若出现本规则规定的违规订单情形，如订单系统不予通过、订单被取消、未按照既定时间发货、不发货等，将按照本规则进行处理，若</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;'+
                    'font-style:normal;font-size:7.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;'+
                    'font-style:normal;font-size:7.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">平台其他规则与本规则规定不一致的，适用本规则规定。</font></span></b><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;'+
                    'font-style:normal;font-size:7.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">2.若出现本规则中规定的违规订单情形，</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;'+
                    'font-style:normal;font-size:7.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;'+
                    'font-style:normal;font-size:7.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">、</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;'+
                    'font-style:normal;font-size:7.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">供应链</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;'+
                    'font-style:normal;font-size:7.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">商家将采取订单系统不予通过、取消订单、不予发货、不向客户提供商品或服务等措施，无任何赔付、补偿。</font></span></b><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;'+
                    'font-style:normal;font-size:7.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">3.若因本规则中违规订单原因，订单被取消等，将按照平台规则中快速退款流程进行退款。</font></span></b><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;'+
                    'font-style:normal;font-size:7.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">4.如对订单取消、不发货等情形有疑问，可与</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;'+
                    'font-style:normal;font-size:7.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;'+
                    'font-style:normal;font-size:7.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">、商家客服进行联系。</font></span></b><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p></div>'
                },
                {
                    name: '违规行为规则',
                    id: 16, 
                    html:'<div class="Section0" style="layout-grid:15.6000pt;"><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">消费者通过</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">账号操作的各种行为应符合法律法规规定、平台规则规定及注册协议约定，当出现（包含但不限于）以下行为时，视为消费者自愿同意接受</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">或商家进行权益降级，强制风险验证、扣除</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">豆、冻结或关闭</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">账号、取消订单、暂停或停止提供服务等操作，且同意</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">或商家不进行任何赔偿或补偿。如给</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">或相关方造成损失，消费者愿意承担相应责任：</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">一、违规行为包含但不限于以下行为：</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">1、&nbsp; 注册资料内容含虚假信息；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">2、&nbsp; 虚假交易、虚假好评,发布无根据的恶意评价；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">3、&nbsp; 购买正品商品，退货时退回非</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">平台对应订单中销售的商品、以次充好、以假乱真等行为；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">4、&nbsp; 无故、无正常理由拒收签收订单货物的行为；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">5、&nbsp; 滥用购买商品或服务、评价、申请退款、申请售后服务、发起投诉或索赔、滥用</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">所提供或赋予的工具等权利，损害他人合法权益或妨害平台运营秩序的行为，如：利用他人的行为瑕疵，以发起投诉、给予恶意差评等手段威胁他人，索取不当利益；</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">6、&nbsp; 符合违规订单处理规则情形的行为；（具体可参见：</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">常见问题分类</font>-订单咨询-违规订单处理</span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">）</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">7、&nbsp; 经</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">判断，</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">会员</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">订购行为不符合公平原则或诚实信用原则的情形。</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;mso-fareast-font-family:微软雅黑;'+
                    'color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;'+
                    'font-style:normal;font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p></div>'
                },
                {
                    name:'云淘通承诺',
                    id:17,
                    html:'<div class="Section0" style="layout-grid:15.6000pt;"><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:宋体;mso-ascii-font-family:Arial;'+
                    'mso-hansi-font-family:Arial;mso-bidi-font-family:Arial;color:rgb(102,102,102);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">1. 我们秉承质优</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">价实</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">、放心满意的销售理念为您服务。我们所出售的商品均为正品行货，与您亲临商场选购的商品一样享受相同的质量保证；含有质量保证书的商品按照保证书的承诺执行，其它商品按国家有关规定执行。</font>&nbsp;</span><span style="mso-spacerun:\'yes\';font-family:Arial;mso-fareast-font-family:宋体;'+
                    'color:rgb(102,102,102);letter-spacing:0.0000pt;text-transform:none;'+
                    'font-style:normal;font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">2. 我们承诺</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">智选</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">在运输</font>“保价费”上永久免费，在配送环节上承担保险费用，运输过程的风险一律由</span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">承担，客户收到货物如果有损坏、遗失等情形，只要当场提出声明，并进行相关举证，</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">将按照正常售后退换货程序予以先行处理。</font>&nbsp;</span><span style="mso-spacerun:\'yes\';font-family:Arial;mso-fareast-font-family:宋体;'+
                    'color:rgb(102,102,102);letter-spacing:0.0000pt;text-transform:none;'+
                    'font-style:normal;font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">3. 我们承诺通过我司提供的网上支付平台</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">兑换</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">，为客户承担全额手续费，但由于客户原因取消正常状态下的订单要求款项返还时（做</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">帐户余额除外），则由客户承担相关手续费；货物打包不收取任何包装费。</font></span><span style="mso-spacerun:\'yes\';font-family:Arial;mso-fareast-font-family:微软雅黑;'+
                    'color:rgb(102,102,102);letter-spacing:0.0000pt;text-transform:none;'+
                    'font-style:normal;font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p></div>'
                },
                {
                    name:'云淘通兑换条款',
                    id:18,
                    html:'<body style="tab-interval:21pt;text-justify-trim:punctuation;"><!--StartFragment--><div class="Section0" style="layout-grid:15.6000pt;"><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:15.1000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">客户在接受商品订购与送货的同时，有义务遵守以下交易条款。您在</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">下订单之前或接受</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">送货之前，请您仔细阅读以下条款：</font></span><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:15.1000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">1. 订购的商品价格以您下订单时</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通平台</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">网上</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">积分</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">价格为准。</font></span><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:15.1000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">2. 请清楚准确地填写您的真实姓名、送货地址及联系方式。因如下情况造成订单延迟或无法配送等，</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">将不承担责任：</font></span><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:15.1000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">&nbsp;A. 客户提供错误信息和不详细的地址；&nbsp;</font></span><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:15.1000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">B. 货物送达无人签收，由此造成的重复配送所产生的费用及相关的后果；&nbsp;</font></span><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:15.1000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">C. 不可抗力，例如：自然灾害、交通戒严、突发战争等。&nbsp;</font></span><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:15.1000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">3. 安全性：无论您是电话订购商品或是网络订购商品，我们会保证交易信息的安全，并由</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">授权的员工</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">或官方</font>QQ客服</span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">处理您的订单。</font>&nbsp;</span><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:15.1000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">4. 隐私权：</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">尊重您的隐私权，在任何情况下，我们都不会将您的个人和订单信息出售或泄露给任何第三方（国家司法机关调取除外）。我们从网站上或电话中得到的所有客户信息仅用来处理您的相关订单。</font>&nbsp;</span><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:15.1000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">5. 免责：如因不可抗力或其它</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">无法控制的原因使</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">销售系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">会尽可能合理地协助处理善后事宜，并努力使客户免受经济损失。</font>&nbsp;</span><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:15.1000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">6. 客户监督：</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">希望通过不懈努力，为客户提供最佳服务，</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">在给客户提供服务的全过程中接受客户的监督。</font>&nbsp;</span><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:15.1000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">7. 争议处理：如果客户与</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">之间发生任何争议，可依据当时双方所认定的协议及相关法律进行解决。</font></span><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:15.1000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;"><b style="mso-bidi-font-weight:normal"><span class="16" style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;'+
                    'font-style:normal;font-size:8.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">特殊说明：</font></span></b><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:15.1000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">1. </font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">所服务的</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">会员</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">为以终端消费为目的的个人、企业、事业单位及其他社会团体，不包括经销商，如发现经销商购物，</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">将拒绝为其服务。由此产生的一切后果由经销商自行承担。</font></span><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:15.1000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">2.如果一个ID帐号在一个月内有过1次以上或一年内有过3次以上（不含本数），因非因</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">原因导致拒收</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">配送的商品，</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">将从相应的</font>ID帐户里按每单扣除</span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">一定的云淘</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">豆做为运费补偿。</font></span><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:15.1000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">即一个用户一个月内，如果拒收次数超过一次，第一次拒收的订单不扣除</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">豆，第二次及以上拒收的订单，每单扣除</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">相应的云淘</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">豆；一个用户一年内，如果拒收次数超过三次，前三次拒收的订单不扣除</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">豆，（其中若存在一个月内有过</font>1次以上的情况，按照以上规定操作）第四次及以上拒收的订单，每单扣除</span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">一定的云淘</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">豆</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">。</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;mso-ascii-font-family:Calibri;'+
                    'mso-hansi-font-family:Calibri;mso-bidi-font-family:\'Times New Roman\';font-size:12.0000pt;'+
                    'mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:15.1000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">时间计算方法为：成功提交订单后向前推算</font>30天为一个月，成功提交订单后向前推算365天为一年，不以自然月和自然年计算。</span><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:15.1000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;"><b style="mso-bidi-font-weight:normal"><span class="16" style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;'+
                    'font-style:normal;font-size:7.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="16" style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;'+
                    'font-style:normal;font-size:7.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">承诺：</font></span></b><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:15.1000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">1. 我们秉承质优价低、放心满意的销售理念为您服务。我们所出售的商品均为正品行货，与您亲临商场选购的商品一样享受相同的质量保证；含有质量保证书的商品按照保证书的承诺执行，其它商品按国家有关规定执行。&nbsp;</font></span><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:15.1000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">2. 我们承诺在</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">智选</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">运输</font>“保价费”上永久免费，在配送环节上承担保险费用，运输过程的风险一律由</span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">承担，客户收到货物如果有损坏、遗失等情形，只要当场提出声明，并进行相关举证，</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">将按照正常售后退换货程序予以先行处理。</font>&nbsp;</span><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:15.1000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">3. 我们承诺通过我司提供的网上支付平台</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">兑换</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">，为客户承担全额手续费，但由于客户原因取消正常状态下的订单要求</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">积分款</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">项返还时（做</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">帐户余额除外），则由客户承担相关手续费；货物打包不收取任何包装费。</font></span><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:15.1000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;"><b style="mso-bidi-font-weight:normal"><span class="16" style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;'+
                    'font-style:normal;font-size:7.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">特别提示：</font></span></b><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:15.1000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">1. 如您购买的商品在规定时间内出现国家法律所规定的性能故障或商品质量问题，需要返修/退换货时，请您在我的</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">提交返修</font>/退换货申请，申请单审核通过后，按照提示信息将物品以普通快递（请别使用到付）的方式寄回</span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">供应链</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">或卖家售后，相关的邮寄运费将以</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">余额的形式返还到您的</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">账户中。如未提交返修</font>/退换货申请或未通过审核而将商品寄回，则</span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">或卖家有权拒绝您的相关申请。具体详情可参见售后政策中的售后服务流程</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">。</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;mso-ascii-font-family:Calibri;'+
                    'mso-hansi-font-family:Calibri;mso-bidi-font-family:\'Times New Roman\';font-size:12.0000pt;'+
                    'mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:15.1000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">2. 我们保证出货时商品外包装的完好无缺。请您在收到货物时当场仔细检查发票及货品与送货单的商品是否一致，如果发现商品缺少或已破损等情况，请当场即配送人员还在现场时与我司客服部联系；如果发现邮寄包装破损，货物在运输途中破损等情况，请当场指出并拒收，拒收后请致电</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">QQ联系</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">我司客服，如您已签收或他人代为签收，则视为商品外包装，商品数量及内容无误，我司将无法受理。电子类商品如手机、相机、笔记本等有原厂包装、标签的</font>3C产品，可以打开原厂包装，但不能安装电池开机启动，不能通电、过水、插入卡槽。请您在确认外观无损的情况下确认收货，如确认收货后，发现外观问题将无法办理。</span><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p></div><!--EndFragment--></body>'
                },
                {
                    name:'交易条款特别提示',
                    id:19,
                    html:'<div class="Section0" style="layout-grid:15.6000pt;"><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">1.如您</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">兑换</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">的商品在规定时间内出现国家法律所规定的性能故障或商品质量问题，需要返修</font>/退换货时，请您在我的</span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">提交</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">返修</font>/退换货申请</span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">，申请单审核通过后，按照提示信息将物品以普通快递（到付除外）的方式寄回</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">供应链</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">或卖家售后，相关的邮寄运费</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">如果符合腿还细则</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">将以</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">余额的形式返还到您的</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">账户中。如未提交返修</font>/退换货申请或未通过审核而将商品寄回，则</span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">或卖家有权拒绝您的相关申请。具体详情可参见售后政策中的</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">《售后服务流程》</font></span><span style="mso-spacerun:\'yes\';font-family:Arial;mso-fareast-font-family:宋体;'+
                    'color:rgb(102,102,102);letter-spacing:0.0000pt;text-transform:none;'+
                    'font-style:normal;font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">2.我们保证出货时商品外包装的完好无缺。请您在收到货物时当场仔细检查</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">票据</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">及货品与送货单的商品是否一致，如果发现商品缺少或已破损等情况，请当场即配送人员还在现场时与我司客服部联系；如果发现邮寄包装破损，货物在运输途中破损等情况，请当场指出并拒收，拒收后请致电我司客服，如您已签收或他人代为签收，则视为商品外包装，商品数量及内容无误，我司将无法受理。电子类商品如手机，相机，笔记本等产品请您在确认外观无损的情况下开机试用，如开机试用后，发现外观问题将无法办理。</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;mso-ascii-font-family:Arial;'+
                    'mso-hansi-font-family:Arial;mso-bidi-font-family:Arial;color:rgb(102,102,102);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p></div>'

                },
                {
                    id:20,
                    name:'在线兑换保障服务',
                    html:'<div class="Section0" style="layout-grid:15.6000pt;"><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);">1.</span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">供应链</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">卖家提供商品的交易中，</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">提供在线交易保障服务，保障您已支付的</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">积分</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">安全。</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">2.您使用在线</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">兑换供应链</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">卖家提供的商品，</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">兑换</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">成功后，</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">与卖家结算</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">，</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">在您验收货物并完成</font>“确认收货”。</span><span style="mso-spacerun:\'yes\';font-family:Tahoma;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;'+
                    'margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;'+
                    'mso-pagination:widow-orphan;mso-line-height-alt:12pt;background:rgb(255,255,255);"><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">3.对于第三方卖家提供的商品，您提交退货申请通过后，如果卖家在收到货后48小时工作日内未反馈处理意见，将由</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:\'yes\';font-family:微软雅黑;color:rgb(0,0,0);'+
                    'letter-spacing:0.0000pt;text-transform:none;font-style:normal;'+
                    'font-size:7.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);'+
                    'mso-shading:rgb(255,255,255);"><font face="微软雅黑">介入为您处理相关订单的售后事宜。</font></span><span style="mso-spacerun:\'yes\';font-family:Tahoma;mso-fareast-font-family:微软雅黑;'+
                    'color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;'+
                    'font-style:normal;font-size:6.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:\'yes\';font-family:Calibri;mso-fareast-font-family:宋体;'+
                    'mso-bidi-font-family:\'Times New Roman\';font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p></div>'
                }
            ]
        }
    ]
}
