<!--
 * @Description: 项目根组件
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-04-05 13:14:48
 -->
<template>
  <div id="app" name="app" :style="setCssVar">
    <el-container>
      <!-- 顶部导航栏 -->
      <div :class=" this.isIndexUrl? 'topbar condole-style':'topbar'">
        <div class="nav">
          <ul>
            <li v-if="!this.$store.getters.getUser">
              <el-button type="text" @click="login">登录</el-button>
              <span class="sep">|</span>
              <el-button type="text" @click="register = true">注册</el-button>
            </li>
            <li v-else>
              欢迎
              <el-popover placement="top" width="180" v-model="visible">
                <p>确定退出登录吗？</p>
                <div style="text-align: right; margin: 10px 0 0">
                  <el-button size="mini" type="text" @click="visible = false"
                    >取消</el-button
                  >
                  <el-button type="primary" size="mini" @click="logout"
                    >确定</el-button
                  >
                </div>
                <el-button type="text" slot="reference">{{
                  this.$store.getters.getUser.Name
                }}</el-button>
              </el-popover>
            </li>
            <li>
              <router-link to="/user/index">个人中心</router-link>
            </li>
            <li>
              <router-link to="/collect">我的收藏</router-link>
            </li>
            <li :class="getNum > 0 ? 'shopCart-full' : 'shopCart'">
              <router-link to="/shoppingCart">
                <i class="el-icon-shopping-cart-full"></i> 购物车
                <span class="num">({{ getNum }})</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <!-- 顶部导航栏END -->

      <!-- 顶栏容器 -->
      <el-header>
        <div :class=" this.isIndexUrl? 'home-header condole-style':'home-header'">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            active-text-color="#409eff"
            router
          >
            <div class="logo">
              <router-link to="/">
                <!-- <img
                  :src="isTest? require('./assets/imgs/log_wdwl.png'):require('./assets/imgs/logo_2.png') "
                  alt
                  style="width: 270px; margin-top: -10px"
                /> -->
                <span class="title">云淘通</span>
              </router-link>
            </div>
            <!-- <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/goods">全部商品</el-menu-item>
          <el-menu-item index="/user/exchange">卡券回收</el-menu-item> -->
            <!-- <el-menu-item index="/about">关于我们</el-menu-item> -->
          </el-menu>
          <div class="so">
            <el-input
              placeholder="请输入搜索内容"
              v-model="search"
              autocomplete="off"
              @keyup.enter.native="searchClick"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="searchClick"
              ></el-button>
            </el-input>
          </div>
        </div>
      </el-header>
      <!-- 顶栏容器END -->

      <!-- 商品分类 -->
      <div :class=" this.isIndexUrl? 'goods-class-tab condole-style':'goods-class-tab'">
        <div class="navTabs" @mouseleave="activeName = -1">
          <ul>
            <router-link to="/" class="router">
              <li
                :class="['navTabs-li', { active: activeName == 'home'}]" 
                @mouseover="activeName = 'home'" 
              >
                首页
              </li>
            </router-link>
            <router-link to="/icp/read" class="router">
              <li
                v-if="isTest"
                :class="['navTabs-li', { active: activeName == 'read' }]"
                @mouseover="activeName = 'read'"
              >
                付费资讯
              </li>
            </router-link>
            <router-link to="/icp/article" class="router">
              <li
                v-if="isTest"
                :class="['navTabs-li', { active: activeName == 'agreement' }]"
                @mouseover="activeName = 'agreement'"
              >
                商家入驻
              </li>
            </router-link>

            <li
              v-for="(item, index) in categoryList"
              :key="index"
              :class="['navTabs-li', { active: activeName == index }]"
              @mouseover="activeName = index"
            >
              <span class="name">{{ item.Name }}</span>
            </li>
            <!-- <li
              :class="['navTabs-li', { active: activeName == 'card' }]"
              @mouseover="activeName = 'card'"
            >
              <router-link to="/user/exchange" class="router"
                >卡券回收</router-link
              >
            </li> -->

            <div class="card-info" v-if="activeName > -1">
              <div class="card-list">
                <ul>
                  <li
                    class="card-item"
                    v-for="(item, index) in categoryList[activeName].TwoTypes"
                    :key="index"
                    @click="toGoods(item.Id)"
                  >
                    <span class="name">{{ item.Name }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </ul>
        </div>
      </div>

      <!-- 商品分类END -->

      <!-- 登录模块 -->
      <MyLogin></MyLogin>
      <!-- 注册模块 -->
      <MyRegister :register="register" @fromChild="isRegister"></MyRegister>

      <!-- 主要区域容器 -->
      <el-main>
        <!-- 右侧工具栏 -->
        <tool-bar :scrollTop="scrollTop"></tool-bar>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </el-main>
      <!-- 主要区域容器END -->

      <!-- 底栏容器 -->
      <el-footer>
        <div :class=" this.isIndexUrl? 'footer condole-style':'footer'">
          <div class="ng-promise-box">
            <div class="ng-promise">
              <p class="text">
                <!-- <a class="icon1" href="javascript:;">7天无理由退换货</a>
                <a class="icon2" href="javascript:;">满99元全场免邮</a>
                <a class="icon3" style="margin-right: 0" href="javascript:;"
                  >100%品质保证</a
                > -->
              </p>
            </div>
          </div>
          <div class="github">
            <div>
              <dl v-for="(item, index) in getHelpMenus" :key="index">
                <dt>{{ item.title }}</dt>
                <dd
                  v-for="(items, indexs) in item.menusItem.slice(0, 3)"
                  :key="indexs"
                >
                  <a
                    v-if="items.linkUrl"
                    :href="items.linkUrl"
                    target="_blank"
                    >{{ items.name }}</a
                  >
                  <router-link
                    v-else
                    :to="`/help?id=${items.id}&index=${index}`"
                    >{{ items.name }}</router-link
                  >
                </dd>
                <dd v-if="item.title == '个人中心' && isTest">
                  <router-link to="/icp/article" class="router"
                    >商家入驻</router-link
                  >
                </dd>
              </dl>

              <div class="appservice">
                <p><a style="font-size: 20px">联系我们</a></p>
                <p><a>固定电话：010-62456788</a></p>
                <p>移动电话：18301256783</p>
                <p>
                  <a
                    href="tencent://message/?uin=741907417&amp;Site=741907417&amp;menu=yes"
                    style="color: #3a8ee6"
                    target="_blank"
                    >客服QQ：741907417</a
                  >
                </p>
              </div>
              <div class="qrcode">
                <div style="line-height: 26px; width: 120px">
                  关注云淘通公众号享受更多优惠
                </div>
                <img src="./assets/imgs/ytt.jpg" width="100px" />
              </div>
            </div>
            <!-- <el-row>
              <el-col :span="12"
                ><img src="./assets/imgs/ytt.jpg" width="100px"
              /></el-col>
              <el-col :span="12"
                ><div>
                  <
                </div></el-col
              >
            </el-row> -->
          </div>
          <div class="mod_help">
            <p>
              <!-- <router-link to="/">首页</router-link>
              <span>|</span>
              <router-link to="/goods">全部商品</router-link> -->
              <!-- <span>|</span>
              <router-link to="/about">关于我们</router-link> -->
            </p>
            <p></p>
            <p class="coty">
              Copyright © 2021 yuntaotong.com 北京云韬物联科技有限公司 版权所有
              <a
                href="https://beian.miit.gov.cn/#/Integrated/index"
                target="_blank"
                >{{isTest?'京ICP备2021006740号-2':'京ICP备2021006740号'}}
              </a>
              <span v-if="isTest">北京市海淀区北清路68号院21号楼2层24室</span>
            </p>
          </div>
        </div>
      </el-footer>
      <!-- 底栏容器END -->
    </el-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import toolBar from "@/components/common/tool-bar.vue";
export default {
  components: {
    toolBar,
  },
  beforeUpdate() {
    this.activeIndex = this.$route.path;
  },
  data() {
    return {
      activeIndex: "", // 头部导航栏选中的标签
      search: "", // 搜索条件
      register: false, // 是否显示注册组件
      visible: false, // 是否退出登录
      scrollTop: 0,
      activeName: "first", //商品分类选中项
      categoryList: [], //分类列表
      host: "",
      apiGlobalConfigData:[],//后台返回的全局配置数据
      isIndexUrl:false
    };
  },
  created() {
    this.host = window.location.host;
    if(this.isTest){
      document.title="云韬物联"
    }
    // 获取浏览器localStorage，判断用户是否已经登录
    if (localStorage.getItem("user")) {
      // 如果已经登录，设置vuex登录状态
      this.setUser(JSON.parse(localStorage.getItem("user")));
    }
    this.getCategory();
    this.getGlobalConfigData();     
    /* window.setTimeout(() => {
      this.$message({
        duration: 0,
        showClose: true,
        message: `
        <p>如果觉得这个项目还不错，</p>
        <p style="padding:10px 0">您可以给项目源代码仓库点Star支持一下，谢谢！</p>
        <p><a href="https://github.com/hai-27/vue-store" target="_blank">Github传送门</a></p>`,
        dangerouslyUseHTMLString: true,
        type: "success"
      });
    }, 1000 * 60); */
  },
  computed: {   
    ...mapGetters(["getUser", "getNum", "getShoppingCart"]),
    isTest() {
      var res = false;
      var host = window.location.host;
      if (host.indexOf(this.$icphost) > -1) {
        res = true;
      }
      // res = true;
      return res;
    },
    getHelpMenus() {
      return this.$helpMenus.helpMenus.filter((item) => !item.toolDisplay);
    },
    setCssVar(){
      return  {
          "--ThemeColor":this.apiGlobalConfigData["Theme"]
      }
    }
  },
  watch: {
    categoryList(e) {
      if (this.isTest && e.length > 4) {
        this.categoryList = e.slice(1, 5);
      }
    },
    // 获取vuex的登录状态
    getUser: function (val) {
      if (val === "") {
        // 用户没有登录
        this.setShoppingCart([]);
      } else {
        // 用户已经登录,获取该用户的购物车信息
        this.$axios
          .get("/api/Cart/GetCarts/GetCarts", {
            params: {
              UserID: val.UserId,
            },
          })
          .then((res) => {
            let cartList = [];
            res.data.forEach((item) => {
              const shoppingCart = {
                id: item.Id, // 购物车id
                productID: item.GoodsId, // 商品id
                productName: item.Name, // 商品名称
                productImg: item.Photo, // 商品图片
                price: item.Price, // 商品价格
                num: item.Numbers, // 商品数量
                maxNum: 0, // 商品限购数量
                minNum: item.LowestBuy, //最小购买数量
                check: false, // 是否勾选
                skuId: item.GoodsSkuId,
                skuName: item.SkuName,
                goodsFromType: item.GoodsFromType,
                State: item.State,
              };
              cartList.push(shoppingCart);
            });
            this.setShoppingCart(cartList);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
    },
    "$route.path":function(val,oldval)
    {
        this.exitsIndexUrl(val);
         //console.log("当前：%s    上一次:%s",val,oldval);
    },
    "apiGlobalConfigData":function(){
      this.exitsIndexUrl(this.$route.path); 
    }
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
  },
  methods: {
    ...mapActions(["setUser", "setShowLogin", "setShoppingCart"]),
    //点击展示二级分类
    handleClick(tab, event) {
      console.log(tab, event);
    },
    login() {
      // 点击登录按钮, 通过更改vuex的showLogin值显示登录组件
      this.setShowLogin(true);
    },
    // 退出登录
    logout() {
      this.visible = false;
      // 清空本地登录信息
      localStorage.setItem("user", "");
      // 清空vuex登录信息
      this.setUser("");
      this.notifySucceed("成功退出登录");
      this.$router.push({ path: "/" });
    },
    // 接收注册子组件传过来的数据
    isRegister(val) {
      this.register = val;
    },
    // 点击搜索按钮
    searchClick() {
      // if(this.search.length<4){
      //   this.notifyError("请输入4个字以上")
      //   return
      // }
      if (this.search != "") {
        // 跳转到全部商品页面,并传递搜索条件
        this.$router.push({ path: "/goods", query: { search: this.search } });
        // this.search = "";
      }
    },
    onScroll() {
      this.scrollTop = window.scrollY;
    },
    // 向后端请求分类列表数据
    getCategory() {
      if (this.categoryList.length > 0) {
        console.log("加载数据");
        return;
      }
      this.$axios
        .get("/api/GoodsType/GetAllType", {})
        .then((res) => {
          const cate = res.data;
          this.categoryList = cate;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    toGoods(id) {
      this.activeName = -1;
      this.$router.push({
        path: "/goods",
        query: { categoryID: id },
      });
    },
    getGlobalConfigData(){
      this.apiGlobalConfigData=[];
      this.$axios
        .get("/api/VouchersGoods/GlobalConfigData", {})
        .then((res) => {
          const cate = res.data;
          this.apiGlobalConfigData = cate;   
          this.$root.apiGlobalConfigData=cate;                        
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    exitsIndexUrl(path){
      if(this.apiGlobalConfigData["CondoleStyle"])
        {
            //监测是否在首页
            if(path=="/")
            {
              this.isIndexUrl=true;
            }
            else{
              this.isIndexUrl=false;
            }          
          }
          else{
              this.isIndexUrl=false;
          }
    }
  },  

};

</script>

<style>
.condole-style {
  -webkit-filter: grayscale(100%); /* chrome+ */
  filter: grayscale(100%); /* 未来浏览器 */
  filter: gray; /* ie6-8 */
  filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);/*ie6-9 */
}
/* 全局CSS */
* {
  padding: 0;
  margin: 0;
  border: 0;
  list-style: none;
}
#app .el-header {
  padding: 0;
  margin-bottom: 20px;
}
#app .el-main {
  min-height: calc(100vh - 378px); /* 保证底部不留白 */
  padding: 20px 0;
  /* z-index: 100; */
  /* padding-bottom: 0px; */
}
#app .el-footer {
  padding: 0;
}
a,
a:hover {
  text-decoration: none;
}
/* 商品来源标签*/
.product-tag {
  border-radius: 10px;
  background-color: #67c23a;
  color: #f5f5f5;
  padding: 0px 10px;
  font-size: 12px;
}
.product-tag.isjd {
  background-color: #ff5933;
}

.myList ul li {
  z-index: 1;
  float: left;
  width: 234px;
  height: 280px;
  padding: 10px 0;
  margin: 0 0 14.5px 13.7px;
  background-color: #fff;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  position: relative;
  border: solid 1px #e6e6e6;
}

/* 左下角标 */
.myList .angle-box {
  color: #fff;
  height: 20px;
  width: 100px;
  /* line-height: 20px; */
  position: absolute;
  right: 140px;
  top: 240px;
  background-color: #67c23a;
  text-align: center;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: rotate(50deg);
  transform: rotate(50deg);
  font-size: 12px;
  z-index: 100;
}
.myList .angle-box.isjd {
  background-color: #ff5933;
}
.myList .angle-box .desc {
  margin-left: 5px;
}
/* 全局CSS END */

/* 顶部导航栏CSS */
.topbar {
  height: 40px;
  background-color: #fafafa;
  margin-bottom: 20px;
}
.topbar .nav {
  max-width: 1225px;
  margin: 0 auto;
}
.topbar .nav ul {
  float: right;
}
.topbar .nav li {
  float: left;
  height: 40px;
  color: #b0b0b0;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  margin-left: 20px;
}
.topbar .nav .sep {
  color: #b0b0b0;
  font-size: 12px;
  margin: 0 5px;
}
.topbar .nav li .el-button {
  color: #b0b0b0;
}
.topbar .nav .el-button:hover {
  /* color: #ff6700; */
  color:var(--ThemeColor,#ff6701);
}
.topbar .nav li a {
  color: #b0b0b0;
}
.topbar .nav a:hover {
  /* color: #ff6700; */
  color:var(--ThemeColor,#ff6701);
}
.topbar .nav .shopCart {
  width: 120px;
  /* background: #424242; */
}
.topbar .nav .shopCart:hover {
  background: #fff;
}
.topbar .nav .shopCart:hover a {
  /* color: #ff6700; */
  color:var(--ThemeColor,#ff6701);
}
.topbar .nav .shopCart-full {
  width: 120px;
  /* background: #ff6701; */
  background:var(--ThemeColor,#ff6701);
}
.topbar .nav .shopCart-full a {
  color: white;
}
/* 顶部导航栏CSS END */

/* 商品分类 */
.goods-class-tab {
  width: 100%;
  height: 40px;
  z-index: 100;
  display: flex;
  justify-content: center;
  /* background-color: #ff6701; */
  background-color: var(--ThemeColor,#ff6701);
}

.goods-class-tab .goods-class {
  position: fixed;
}

.goods-class-tab .goods-class .card-item {
  float: left;
  padding: 20px;
}
.goods-class-tab .goods-class .el-tabs__content {
  padding: 0;
}

.goods-class-tab .navTabs-li {
  width: 120px;
  height: 40px;
  float: left;
  color: #fff;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
}

.goods-class-tab .navTabs-li.active {
  background-color: #ffffff80;
  /* color: #ff6701; */
  color:var(--ThemeColor,#ff6701);
}
.goods-class-tab .navTabs-li .router {
  color: #fff;
}
.goods-class-tab .navTabs-li.active .router {
  /* color: #ff6701; */
  color:var(--ThemeColor,#ff6701);
}
.goods-class-tab .card-info {
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 101;
}

.goods-class-tab .card-info .card-item {
  float: left;
  width: 100px;
  padding-left: 50px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}

.goods-class-tab .card-info .card-list {
  position: absolute;
  width: 1024px;
  background-color: #fff;
  /* border: 1px solid #ff6701; */
  border: 1px solid var(--ThemeColor,#ff6701);
  border-top: none;
  display: block;
  min-height: 120px;
  padding: 20px;
}
.goods-class-tab .card-info .card-item .name:hover {
  /* color: #ff6700; */
  color: var(--ThemeColor,#ff6701);
}
/* 商品分类END */

/* 顶栏容器CSS */
.home-header {
  max-width: 1225px;
  margin: 0 auto;
}
.el-header .el-menu {
  /* max-width: 1225px;
  margin: 0 auto; */
  width: 320px;
  float: left;
}
.el-header .el-menu .el-menu-item {
  font-size: 16px;
}
.el-header .logo {
  height: 80px;
  width: 280px;
  float: left;
  margin-right: 100px;
  margin-top: -10px;
}
.el-header .logo .title {
  /* color: #ff6701; */
  color: var(--ThemeColor,#ff6701);
  font-size: 78px;
  font-weight: bold;
  font-family: "华文行楷", "STXingkai";
}
.el-header .so {
  margin-top: 10px;
  width: 300px;
  float: right;
}
.el-header .el-menu.el-menu--horizontal {
  border-bottom: none;
}
/* 顶栏容器CSS END */

/* 底栏容器CSS */
.footer {
  width: 100%;
  text-align: center;
  /* background: #2f2f2f; */
  padding-bottom: 20px;
  /* border-top: 12px solid #ff6701; */
  border-top: 12px solid var(--ThemeColor,#ff6701);
}
.footer .ng-promise-box {
  /* border-bottom: 1px solid #3d3d3d; */
  line-height: 145px;
}
.footer .ng-promise-box {
  margin: 0 auto;
  /* border-bottom: 1px solid #3d3d3d; */
  line-height: 145px;
}
.footer .ng-promise-box .ng-promise p a {
  color: #fff;
  font-size: 20px;
  margin-right: 210px;
  padding-left: 44px;
  height: 40px;
  display: inline-block;
  line-height: 40px;
  text-decoration: none;
  background: url("./assets/imgs/us-icon.png") no-repeat left 0;
}
.footer .github {
  /* height: 50px; */
  line-height: 50px;
  margin-top: 20px;
}
.footer .github .github-but {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  background: url("./assets/imgs/github.png") no-repeat;
}
footer .github .appservice {
  line-height: 40px;
  margin-top: 6px;
  margin-left: 40px;
  /* color: #e0e0e0; */
}
.footer .mod_help {
  text-align: center;
  color: #888888;
}
.footer .mod_help p {
  margin: 20px 0 16px 0;
}

.footer .mod_help p a {
  color: #888888;
  text-decoration: none;
  margin-left: 20px;
}
.footer .mod_help p a:hover {
  /* color: #ff6700; */
  color: var(--ThemeColor,#ff6701);
}
.footer .mod_help p span {
  padding: 0 22px;
}
.footer .github .qrcode {
  margin-top: 10px;
  margin-left: 60px;
}
.footer .github > div {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.footer .github dl {
  /* display: inline-block; */
  float: left;
  width: 150px;
  /* line-height: 12px; */
}

.footer .github dt {
  margin-bottom: 10px;
  font-size: 20px;
  color: #000;
  font-weight: normal;
}

.footer .github dd a {
  line-height: 24px;
  font-size: 12px;
  color: #606060;
}
.footer .github dd a:hover {
  color: var(--ThemeColor,#ff6701);
}

.footer .github dd {
  line-height: 30px;
}
/* 底栏容器CSS END */
</style>