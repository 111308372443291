<!--
 * @Description: 用户注册组件
 * @Author: hai-27
 * @Date: 2020-02-19 22:20:35
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-01 15:34:34
 -->
<template>
  <div id="register">
    <el-dialog
      v-if="isRegister"
      :title="getTitle"
      width="300px"
      center
      :visible.sync="isRegister"
      :close-on-click-modal="false"
    >
      <el-form
        :model="RegisterUser"
        :rules="rules"
        status-icon
        ref="ruleForm"
        class="register-form"
      >
        <el-form-item prop="userName" v-if="!registerTitle">
          <el-input
            prefix-icon="el-icon-user-solid"
            placeholder="请输入昵称"
            v-model="RegisterUser.userName"
            @blur="setStorage()"
            v-focus="true"
          ></el-input>
        </el-form-item>
        <el-form-item prop="name">
          <el-input
            prefix-icon="el-icon-mobile-phone"
            placeholder="请输入手机号"
            v-model="RegisterUser.name"
            maxlength="11"
            @blur="setStorage()"
            v-focus="registerTitle"
          ></el-input>
        </el-form-item>
        <el-form-item prop="pass" class="pawssWord">
          <password-input :passWord.sync="RegisterUser.pass"></password-input>
        </el-form-item>
        <el-form-item prop="confirmPass" class="pawssWord">
          <password-input
            :passWord.sync="RegisterUser.confirmPass"
            placeholder="请再次输入密码"
          ></password-input>
        </el-form-item>
        <el-form-item prop="registerCode">
          <!-- <el-input
            placeholder="请输入验证码"
            v-model="RegisterUser.verificationCode"
          >
            <template slot="append">
              <el-button :disabled="sendBtn.disabled" @click="send">{{
                sendBtn.text
              }}</el-button>
            </template>
          </el-input> -->
          <codeInput
            :verificationCode.sync="RegisterUser.verificationCode"
            :phoneNumber="RegisterUser.name"
            :sendBtn="sendBtn"
            :isRegister="!registerTitle"
          ></codeInput>
        </el-form-item>
        <el-form-item class="agreement">
          <el-checkbox v-model="checked" :label="1">
            <template>
              已经阅读并同意<a href="#/login/agreement?index=0" target="_blank"
                >《用户注册协议》</a><a href="#/login/agreement?index=1" target="_blank"
                >《隐私政策》</a>
            </template>
          </el-checkbox>
        </el-form-item>
         <el-form-item class="agreement">
           <a href="#/login/agreement?index=2" target="_blank"
                >《商城服务账号注销须知》</a>
         </el-form-item>
        <el-form-item>
          <el-button
            size="medium"
            type="primary"
            @click="Register"
            style="width: 100%"
            >{{ getTitle }}</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import codeInput from "@/components/common/code-input.vue";
export default {
  name: "MyRegister",
  props: {
    register: {
      type: Boolean,
      default: false,
    },
    registerTitle: {
      type: String,
      default: "",
    },
  },
  components: {
    codeInput,
  },
  //自定义指令：输入框自动聚焦
  directives: {
    focus: {
      inserted: function (el, binding) {
        // el.focus()
        // 聚焦元素
        var vaule = binding.value;
        if (vaule) {
          el.querySelector("input").focus();
        }
      },
    },
  },
  data() {
    // 用户名的校验方法
    let validateName = (rule, value, callback) => {
      this.sendBtn.disabled = true;
      if (!value) {
        return callback(new Error("请输入手机号"));
      }
      // 用户名以字母开头,长度在5-16之间,允许字母数字下划线
      // const userNameRule = /^[a-zA-Z][a-zA-Z0-9_]{4,15}$/;
      
      //手机号验证        
      var phoneNumberRuleText= this.$root.apiGlobalConfigData['PhoneNumberRule'];
      if(phoneNumberRuleText)
      {       
          var phoneNumberRule;
          try {
            //"(1[8-9]\\d{9}$)"
            phoneNumberRule=new RegExp(phoneNumberRuleText,"g");  
          } catch (error) {
            phoneNumberRule=null;
          }
           if(phoneNumberRule)
           {
            this.$regular.phoneNumber=phoneNumberRule;
           }  

           console.log(this.$regular.phoneNumber);
      }

      if (this.$regular.phoneNumber.test(value)) {
        if (value != this.oldPhone && !this.registerTitle) {
          //判断数据库中是否已经存在该用户名
          this.$axios
            .get(
              "/api/User/IsHasAccount?phoneNumber=" + this.RegisterUser.name,
              ""
            )
            .then((res) => {
              if (!res.data) {
                this.oldPhone = value;
                this.sendBtn.disabled = false;
                this.$refs.ruleForm.validateField("pass");
                return callback();
              } else {
                return callback(new Error("手机号已注册"));
              }
            })
            .catch((err) => {
              return callback(new Error("服务器出现错误"));
            });
        } else {
          this.sendBtn.disabled = false;
          this.$refs.ruleForm.validateField("pass");
          return callback();
        }

        // this.$refs.ruleForm.validateField("checkPass");
        // return callback();
      } else {
        return callback(new Error("请输入正确的手机号"));
      }
    };
    // 密码的校验方法
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        if (!this.registerTitle) {
          return callback(new Error("请输入密码"));
        } else {
          return callback(new Error("请输入新密码"));
        }
      }
      // 密码以字母开头,长度在6-18之间,允许字母数字和下划线
      if (this.$regular.password.test(value)) {
        this.$refs.ruleForm.validateField("confirmPass");
        return callback();
      } else {
        return callback(
          new Error("字母开头,长度6-18之间,允许字母数字和下划线")
        );
      }
    };
    // 确认密码的校验方法
    let validateConfirmPass = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入确认密码"));
      }
      // 校验是否以密码一致
      if (this.RegisterUser.pass != "" && value === this.RegisterUser.pass) {
        this.$refs.ruleForm.validateField("registerCode");
        return callback();
      } else {
        return callback(new Error("两次输入的密码不一致"));
      }
    };
    let validateRegCode = (rule, value, callback) => {
      if (this.RegisterUser.verificationCode != "") {
        this.$refs.ruleForm.validateField("itemRadio");
        return callback();
      } else {
        return callback(new Error("请输入验证码"));
      }
    };
    let validateUserName = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入昵称"));
      } else {
        this.$refs.ruleForm.validateField("name");
        return callback();
      }
    };
    return {
      isRegister: false, // 控制注册组件是否显示
      RegisterUser: {
        name: "",
        pass: "",
        confirmPass: "",
        verificationCode: "",
        userName: "",
      },
      // 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
      rules: {
        name: [{ validator: validateName, trigger: "blur" }],
        pass: [{ validator: validatePass, trigger: "blur" }],
        confirmPass: [{ validator: validateConfirmPass, trigger: "blur" }],
        registerCode: [{ validator: validateRegCode, trigger: "blur" }],
        userName: [{ validator: validateUserName, trigger: "blur" }],
      },
      sendBtn: {
        text: "获取验证码",
        disabled: true,
      },
      oldPhone: "",
      title: "注册",
      storageKey: "RegisterUser",
      checked: false,
    };
  },
  watch: {
    // 监听父组件传过来的register变量，设置this.isRegister的值
    register(val) {
      if (val) {
        this.isRegister = val;
      }
    },
    // 监听this.isRegister变量的值，更新父组件register变量的值
    isRegister: function (val) {
      if (!val) {
        this.checked = false;
        this.$refs["ruleForm"].resetFields();
        // this.$refs["ruleForm"].clearValidate()
        // this.RegisterUser.pass=this.RegisterUser.confirmPass=this.RegisterUser.verificationCode=""
        this.$emit("fromChild", val);
      } else {
        const storage = localStorage.getItem(this.storageKey);
        if (storage) {
          const data = JSON.parse(storage);
          this.RegisterUser.name = data.phone;
          this.RegisterUser.userName = data.name;
        }
      }
    },
  },
  computed: {
    getTitle() {
      let title = "注册";
      if (this.registerTitle) {
        title = this.registerTitle;
      }
      return title;
    },
  },
  methods: {
    //缓存用户名与手机号
    setStorage() {
      let data = {
        phone: "",
        name: "",
      };
      if (this.RegisterUser.name) {
        data.phone = this.RegisterUser.name;
      }
      if (this.RegisterUser.userName) {
        data.name = this.RegisterUser.userName;
      }
      if (data.phone || data.name) {
        localStorage.setItem(this.storageKey, JSON.stringify(data));
      }
    },
    Register() {
      if (!this.checked) {
        this.notifyError("请仔细阅读《用户注册协议》");
        return;
      }
      // 通过element自定义表单校验规则，校验用户输入的用户信息
      this.$refs["ruleForm"].validate((valid) => {
        //如果通过校验开始注册
        if (valid) {
          if (this.registerTitle) {
            const query = {
              PhoneNumber: this.RegisterUser.name,
              Password: this.RegisterUser.pass,
              Password2: this.RegisterUser.pass,
              VerificationCode: this.RegisterUser.verificationCode,
            };
            this.$axios
              .post("/api/User/UpdatePasswordByPhone", this.qs.stringify(query))
              .then((res) => {
                if (res.data) {
                  // 隐藏注册组件
                  this.isRegister = false;
                  // 弹出通知框提示注册成功信息
                  this.notifySucceed(res.data.msg);
                }
              });
          } else {
            this.$axios
              .post("/api/User/Register", {
                Name: this.RegisterUser.userName,
                PhoneNumber: this.RegisterUser.name,
                Password: this.RegisterUser.pass,
                Password2: this.RegisterUser.pass,
                VerificationCode: this.RegisterUser.verificationCode,
              })
              .then((res) => {
                if (res.data) {
                  // 隐藏注册组件
                  this.isRegister = false;
                  // 弹出通知框提示注册成功信息
                  this.notifySucceed(res.data.msg);
                }
              });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.register-form {
  .el-form-item {
    &.agreement {
      /* 水平居中 */
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        color: #606266;
        font-size: 12px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.el-button--medium{
  background-color:   var(--ThemeColor,#ff6701);
    border-color:   var(--ThemeColor,#ff6701);
}
.el-button--medium:hover{
  opacity: 0.8;
  background-color:   var(--ThemeColor,#ff6701);
    border-color:   var(--ThemeColor,#ff6701);
}
</style>