<!--
 * @Description: 菜单组件，用于首页商品展示模块的右上角菜单
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-26 22:52:35
 -->
<template>
  <div class="myMenu" id="myMenu">
    <ul>
      <li
        v-for="(item,index) in val.TwoTypes"
        :key="item.Id"
        :class="activeClass == index ? 'active' : ''"
        @mouseover="mouseover($event, index)"
      >
        <router-link to>
          <slot :name="item.Id"></slot>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    val: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  name: "MyMenu",
  data() {
    return {
      activeClass: 0,
    };
  },
  created() {
    // if (this.val[0]) {
    //   this.activeClass = this.val[0].Id;
    // }
  },
  methods: {
    // 通过mouseover事件控制当前显示的商品分类，1为该类别的热门商品
    mouseover(e, val) {
      this.activeClass = val;
    },
  },
  watch: {
    // 向父组件传过去当前要显示的商品分类，从而更新商品列表
    activeClass: function (val) {
      this.$emit("fromChild",this.val.TwoTypes[val],this.val.Id);
    },
  },
};
</script>
<style scoped>
#myMenu li {
  float: left;
  margin-left: 30px;
}

#myMenu a:hover {
  color: #ff6700;
  border-bottom: 2px solid #ff6700;
}

#myMenu .active a {
  color: #ff6700;
  border-bottom: 2px solid #ff6700;
}
</style>