<template>
  <div class="tool-wrapper" @mouseleave="activeIndex = -1">
    <div v-for="(item, index) in tools" :key="index">
      <a
        v-if="!onlyGotopBtn && index < toolLength"
        class="tool"
        :href="item.linkUrl || 'javascript:void(0);'"
        :target="item.linkUrl ? '_blank' : '_self'"
        :style="{ cursor: item.linkUrl ? 'pointer' : 'default' }"
        @mouseenter="activeIndex = index"
      >
        <img
          class="icon"
          :src="activeIndex == index ? item.iconActiveUrl : item.iconUrl"
        />
        <div class="name">{{ item.name }}</div>
      </a>
    </div>

    <!-- 回到顶部按钮 -->
    <div
      v-show="scrollTop > 1000"
      :class="['tool btn-totop', { onlyme: onlyGotopBtn }]"
      @mouseenter="activeIndex = toolLength"
      @click="goTop"
    >
      <img
        class="icon"
        :src="activeIndex == toolLength ? tools[toolLength].iconActiveUrl : tools[toolLength].iconUrl"
      />
      <div class="name">{{ tools[toolLength].name }}</div>
    </div>

    <!-- 二维码弹窗 -->
    <!-- <div class="qrcode-popup" v-if="activeIndex == 0">
      <img
        class="qrcode"
        src="https://i8.mifile.cn/b2c-mimall-media/93650133310ec1c385487417a472a26c.png"
      />
      <div class="desc">手机扫一扫一分赢好礼</div>
    </div> -->
  </div>
</template>

<script>
import toolBarConfig from "@/common/config/home/tool-bar.config.js";
export default {
  props: {
    scrollTop: {
      type: Number,
      default: 0,
    },
    onlyGotopBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ...toolBarConfig,
      activeIndex: -1,
	  time: 0,
      dParams: 20,
      scrollState: 0,
	  toolLength:toolBarConfig.tools.length-1
    };
  },
  methods: {
	getToolsCount(){

	} ,
    goTop(e) {
     
      if (this.scrollState>0) {
        return;
      }
      this.scrollState = 1;
      e.preventDefault();
      let distance =document.documentElement.scrollTop || document.body.scrollTop;
      let _this = this;
      this.time = setInterval(function () {
        _this.gotoTop(_this.scrollTop - _this.dParams);
      }, 10);
      // window.scrollTo(0,0);
      // uni.pageScrollTo({
      // 	scrollTop: 0,
      // 	duration: 100
      // });
    },
	 gotoTop(distance){
        this.dParams += 20;
        distance = distance>0 ? distance : 0;
        document.documentElement.scrollTop = document.body.scrollTop = window.pageYOffset = distance;
        if(this.scrollTop < 10){
          clearInterval(this.time);
          this.dParams = 20;
          this.scrollState = 0;
        }
      },
  },
};
</script>

<style lang="scss">
.tool-wrapper {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  display: none;

  @media screen and (min-width: 1406px) {
    display: block !important;
  }

  .tool {
    display: block;
    width: 82px;
    height: 90px;
    margin-top: -1px;
    border: 1px solid #f5f5f5;
    text-align: center;
    background-color: #ffffff;

    &:hover {
      .name {
        color: #ff6700;
      }
    }

    &.btn-totop {
      cursor: pointer;
      margin-top: 14px;

      &.onlyme {
        margin-top: 500px;
      }
    }

    .icon {
      width: 30px;
      height: 30px;
      margin-top: 18px;
    }

    .name {
      color: #757575;
    }
  }

  %arrow {
    content: "";
    position: absolute;
    top: 20px;
    border: 8px solid transparent;
  }

  .qrcode-popup {
    position: absolute;
    right: 100%;
    top: 0;
    margin-right: 15px;
    padding: 14px;
    background-color: #ffffff;
    border: 1px solid #f5f5f5;

    .qrcode {
      width: 100px;
      height: 100px;
      margin: 6px auto 0;
    }

    .desc {
      margin: 12px auto 0;
      color: #757575;
      text-align: center;
      width: 82px;
    }

    &::before {
      @extend %arrow;
      right: -17px;
      border-left-color: #f5f5f5;
    }

    &::after {
      @extend %arrow;
      right: -16px;
      border-left-color: #ffffff;
    }
  }
}
</style>
