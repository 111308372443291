<template>
  <el-input placeholder="请输入验证码" v-model="code" @change="onChange">
    <template slot="append">
      <el-button :disabled="disabled" @click="send">{{ text }}</el-button>
    </template>
  </el-input>
</template>
<script>
export default {
  props: {
    verificationCode: {
      type: String,
      default: "",
    },
    phoneNumber: {
      type: String,
      default: "",
    },
    sendBtn: {
      type: Object,
      default() {
        return {};
      },
    },
    isRegister: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      code: "",
      text: this.sendBtn.text,
      disabled:true ,
      seconds: 0,
      timer:null,
      refSend:60,
    };
  },
  created(){
    this.seconds= localStorage.getItem("codeSeconds")
  },
  watch: {
    verificationCode(e) {
      this.code = e;
    },
    sendBtn: {
      deep: true,
      handler(e) {
        this.text = e.text;
        this.disabled = e.disabled;
      },

      //   this.send={
      //   ...Object.assign(this.send, e),
      // }
    },
    seconds(e) {
      this.disabled = this.sendBtn.disabled;
      if(e>0){
        this.disabled = true;
        let number=e
        this.timer = setInterval(() => {
        if (number <= 0) {
          clearInterval(this.timer);
          this.disabled = false;
          this.text = "重新发送";
          this.seconds=0
          localStorage.removeItem("codeSeconds");
        } else {
          this.text = `${number} S`;
          number--;
          this.disabled = true;
          localStorage.setItem("codeSeconds", number);
        }
      }, 1000);
      }
      else{
        this.disabled = false;
      }
     
    },
  },
  methods: {
    // 发送短信
    send() {
      
      if (!this.disabled) {
        clearInterval(this.timer);
        this.disabled = true;
        this.text = "发送中...";
        this.$axios
          .get("/api/User/GetRegisterCode", {
            params: {
              isRegister: this.isRegister,
              phoneNumber: this.phoneNumber,
            },
          })
          .then((res) => {
            if (res.data) {
              this.seconds = this.refSend;
               localStorage.setItem("codeSeconds", this.seconds);
            } else {
              this.notifyError("发送失败");
              this.captcha = "";
              this.code = "";
              this.disabled = false;
              this.text = "重新发送";
              this.step = 0;
            }
          })
          .catch(() => {
            this.captcha = "";
            this.code = "";
            this.disabled = false;
            this.text = "重新发送";
            this.step = 0;
          });
      }
    },
    onChange(val) {
      this.$emit("update:verificationCode", val);
    },
  },
};
</script>