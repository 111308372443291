/*
 *地址信息
 */
export default {
    state: {
        provinceList: [],
        cityList: [],
        district: [],
        street:[]
    },
    getters: {
        getProvince(state) {
            return state.provinceList
        },
        getCity: (state) => (code) => {
            return state.cityList.find(item => item.code == code)
        },
        getDistrict: (state) => (code) => {
            return state.district.find(item => item.code == code)
        },
        getStreet: (state) => (code) => {
            var date=state.street.find(item => item.code == code)
            return date
        },
    },
    mutations: {
        setProvince(state, data) {
            state.provinceList = data
        },
        setCity(state, data) {
            const mo = state.cityList.find(item => item.code == data.code)
            if (!mo) {
                state.cityList.push(data)
            }
        },
        setDistrict(state, data) {
            const mo = state.district.find(item => item.code == data.code)
            if (!mo) {
                state.district.push(data)
            }
        },
        setStreet(state, data) {
            const mo = state.street.find(item => item.code == data.code)
            if (!mo) {
                state.street.push(data)
            }
        }
    },
    actions: {
        setProvince({ commit }, data) {
            commit('setProvince', data);
        },
        setCity({ commit }, data) {
            commit('setCity', data);
        },
        setDistrict({ commit }, data) {
            commit('setDistrict', data);
        },
        setStreet({ commit }, data) {
            commit('setStreet', data);
        },
    }
}