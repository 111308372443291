/*
 * 入口文件
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-04 23:38:41
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/global.css';
Vue.use(ElementUI);

// 全局函数及变量
import Global from './Global';
Vue.use(Global);
import Axios from 'axios';
Vue.prototype.$axios = Axios;
import util from './common/tools/util.js';
Vue.prototype.$util = util;
import regular from './common/tools/regular.js';
Vue.prototype.$regular = regular;//校验规则
import qs from 'qs'
Vue.prototype.qs = qs
import helpMenus from '@/common/config/help/help-menus.js'
Vue.prototype.$helpMenus = helpMenus

// 全局请求拦截器
Axios.interceptors.request.use(
  config => {
    // if (config.method == 'post') {

    //   config.data = qs.stringify(config.data)
    //   console.log(config)
    //   // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    // }
    // console.log(config)
    let token = store.getters.getUser.Token
    if (token) {
      config.headers["Authorization"] = "Bearer " + token
    }
    return config;
  },
  error => {
    // 跳转error页面
    router.push({ path: "/error" });
    return Promise.reject(error);
  }
);
// 全局响应拦截器
Axios.interceptors.response.use(
  res => {
    // if (res.data.code === "401") {
    //   // 401表示没有登录
    //   // 提示没有登录
    //   Vue.prototype.notifyError(res.data.msg);
    //   // 修改vuex的showLogin状态,显示登录组件
    //   store.dispatch("setShowLogin", true);
    // }
    // if (res.data.code === "500") {
    //   // 500表示服务器异常
    //   // 跳转error页面
    //   // router.push({ path: "/error" });
    // }
    // switch(res.data.code){
    //   case "400" :
    //     this.notifyError()
    // }
    let data = res.data
    if (data.head) {
      if (data.head.code != 10000) {
        Vue.prototype.notifyError(data.head.message)
        data = {}
      }
      else {
        data = data.body
      }
    }
    else {
      data = res
    }

    return data;
  },
  error => {
    // 跳转error页面
    // router.push({ path: "/error" });
    const res = error.response
    if (res.status == 401) {
      Vue.prototype.notifyError("未存在登录信息，请重新登录");
      // 清空本地登录信息
      localStorage.setItem("user", "");
      // 清空vuex登录信息
      store.dispatch("setUser", "");
      store.dispatch("setShowLogin", true);
    }
    else if(res.status == 500){
      Vue.prototype.notifyError("服务器出现错误")
    }
    else {
      if (res.data) {
        Vue.prototype.notifyError(res.data)
      }
      else {
        Vue.prototype.notifyError(error.message)
      }
    }
    return Promise.reject(error);
  }
);
// 全局拦截器,在进入需要用户权限的页面前校验是否已经登录
router.beforeResolve((to, from, next) => {
  // alert(Browser.client.name+ " " +Browser.client.version + "  " + Browser.client.type,'1234')
  //提示用户切换浏览器模式
  const url=require("./assets/imgs/jsms.png")
  if(!Browser.client.isWebkit && Browser.client.isStrict){
    ElementUI.MessageBox.alert('  <img src="'+url+'" width="400px" />',"请将浏览器切换为极速模式",{
      dangerouslyUseHTMLString: true,
      showConfirmButton:false
    })
   
  }
  const loginUser = store.state.user.user;
  // 判断路由是否设置相应校验用户权限
  if (to.meta.requireAuth) {
    if (!loginUser) {
      // 没有登录，显示登录组件
      store.dispatch("setShowLogin", true);
      if (from.name == null) {
        //此时，是在页面没有加载，直接在地址栏输入链接，进入需要登录验证的页面
        next("/");
        return;
      }
      //记录拦截的页面路由
      sessionStorage.setItem("beforePath", JSON.stringify(to))
      // 终止导航
      next(false);
      return;
    }
  }
  next();
});
// 解决bug：页面/路由跳转后，滚动条消失，页面无法滚动
router.afterEach((to, from, next) => {
  document.querySelector("body").setAttribute("style", "overflow: auto !important;")
});
// 相对时间过滤器,把时间戳转换成时间
// 格式: 2020-02-25 21:43:23
Vue.filter('dateFormat', (dataStr) => {
  var time = new Date(dataStr);
  function timeAdd0(str) {
    if (str < 10) {
      str = '0' + str;
    }
    return str;
  }
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  return y + '-' + timeAdd0(m) + '-' + timeAdd0(d) + ' ' + timeAdd0(h) + ':' + timeAdd0(mm) + ':' + timeAdd0(s);
});

Vue.filter("formatMoney", (data) => {
  if (!data) return '0.00'
	  // 将数据分割，保留两位小数
	  data= data.toFixed(2)
	  // 获取整数部分
	  const intPart = Math.trunc(data)
	  // 整数部分处理，增加,
	  const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
	  // 预定义小数部分
	  let floatPart = '.00'
	  // 将数据分割为小数部分和整数部分
	  const newArr = data.toString().split('.')
	  if (newArr.length === 2) { // 有小数部分
	    floatPart = newArr[1].toString() // 取得小数部分
	    return intPartFormat + '.' + floatPart
	  }
	  return intPartFormat + floatPart;
});

Vue.filter("formatMoneySimple", (data) => {
  if (!data) return '0.00'
	  // 将数据分割，保留两位小数
	  return data.toFixed(2);
});


//全局注册自定义指令，用于判断当前图片是否能够加载成功，可以加载成功则赋值为img的src属性，否则使用默认图片
Vue.directive('real-img', async function (el, binding) {//指令名称为：real-img
  let imgURL = binding.value;//获取图片地址
  if (imgURL) {
    let exist = await imageIsExist(imgURL);
    if (exist) {
      el.setAttribute('src', imgURL);
    }
  }
})

/**
* 检测图片是否存在
* @param url
*/
let imageIsExist = function (url) {
  return new Promise((resolve) => {
    var img = new Image();
    img.onload = function () {
      if (this.complete == true) {
        resolve(true);
        img = null;
      }
    }
    img.onerror = function () {
      resolve(false);
      img = null;
    }
    img.src = url;
  })
}


Array.prototype.remove = function (val) {
  var index = this.indexOf(val);
  if (index > -1) {
    this.splice(index, 1);
  }
};

var Browser = Browser || (function (window) {
  var document = window.document,
    navigator = window.navigator,
    agent = navigator.userAgent.toLowerCase(),
    //IE8+支持.返回浏览器渲染当前文档所用的模式
    //IE6,IE7:undefined.IE8:8(兼容模式返回7).IE9:9(兼容模式返回7||8)
    //IE10:10(兼容模式7||8||9)
    IEMode = document.documentMode,
    //chorme
    chrome = window.chrome || false,
    System = {
      //user-agent
      agent: agent,
      //是否为IE
      isIE: /msie/.test(agent),
      //Gecko内核
      isGecko: agent.indexOf("gecko") > 0 && agent.indexOf("like gecko") < 0,
      //webkit内核
      isWebkit: agent.indexOf("webkit") > 0,
      //是否为标准模式
      isStrict: document.compatMode === "CSS1Compat",
      //是否支持subtitle
      supportSubTitle: function () {
        return "track" in document.createElement("track");
      },
      //是否支持scoped
      supportScope: function () {
        return "scoped" in document.createElement("style");
      },
      //获取IE的版本号
      ieVersion: function () {
        try {
          return agent.match(/msie ([\d.]+)/)[1] || 0;
        } catch (e) {
          console.log("error");
          return IEMode;
        }
      },
      //Opera版本号
      operaVersion: function () {
        try {
          if (window.opera) {
            return agent.match(/opera.([\d.]+)/)[1];
          } else if (agent.indexOf("opr") > 0) {
            return agent.match(/opr\/([\d.]+)/)[1];
          }
        } catch (e) {
          console.log("error");
          return 0;
        }
      },
      //描述:version过滤.如31.0.252.152 只保留31.0
      versionFilter: function () {
        if (arguments.length === 1 && typeof arguments[0] === "string") {
          var version = arguments[0];
          let start = version.indexOf(".");
          if (start > 0) {
            let end = version.indexOf(".", start + 1);
            if (end !== - 1) {
              return version.substr(0, end);
            }
          }
          return version;
        } else if (arguments.length === 1) {
          return arguments[0];
        }
        return 0;
      }
    };

  try {
    //浏览器类型(IE、Opera、Chrome、Safari、Firefox)
    System.type = System.isIE ? "IE" :
      window.opera || (agent.indexOf("opr") > 0) ? "Opera" :
        (agent.indexOf("chrome") > 0) ? "Chrome" :
          //safari也提供了专门的判定方式
          window.openDatabase ? "Safari" :
            (agent.indexOf("firefox") > 0) ? "Firefox" :
              'unknow';

    //版本号
    System.version = (System.type === "IE") ? System.ieVersion() :
      (System.type === "Firefox") ? agent.match(/firefox\/([\d.]+)/)[1] :
        (System.type === "Chrome") ? agent.match(/chrome\/([\d.]+)/)[1] :
          (System.type === "Opera") ? System.operaVersion() :
            (System.type === "Safari") ? agent.match(/version\/([\d.]+)/)[1] :
              "0";

    //浏览器外壳
    System.shell = function () {
      //遨游浏览器
      if (agent.indexOf("maxthon") > 0) {
        System.version = agent.match(/maxthon\/([\d.]+)/)[1] || System.version;
        return "傲游浏览器";
      }
      //QQ浏览器
      if (agent.indexOf("qqbrowser") > 0) {
        System.version = agent.match(/qqbrowser\/([\d.]+)/)[1] || System.version;
        return "QQ浏览器";
      }

      //搜狗浏览器
      if (agent.indexOf("se 2.x") > 0) {
        return '搜狗浏览器';
      }

      //Chrome:也可以使用window.chrome && window.chrome.webstore判断
      if (chrome && System.type !== "Opera") {
        var external = window.external,
          clientInfo = window.clientInformation,
          //客户端语言:zh-cn,zh.360下面会返回undefined
          clientLanguage = clientInfo.languages;

        //猎豹浏览器:或者agent.indexOf("lbbrowser")>0
        if (external && 'LiebaoGetVersion' in external) {
          return '猎豹浏览器';
        }
        //百度浏览器
        if (agent.indexOf("bidubrowser") > 0) {
          System.version = agent.match(/bidubrowser\/([\d.]+)/)[1] ||
            agent.match(/chrome\/([\d.]+)/)[1];
          return "百度浏览器";
        }
        //360极速浏览器和360安全浏览器
        if (System.supportSubTitle() && typeof clientLanguage === "undefined") {
          //object.key()返回一个数组.包含可枚举属性和方法名称
          var storeKeyLen = Object.keys(chrome.webstore).length,
            v8Locale = "v8Locale" in window;
          return storeKeyLen > 1 ? '360极速浏览器' : '360安全浏览器';
        }
        return "Chrome";
      }
      return System.type;
    };

    //浏览器名称(如果是壳浏览器,则返回壳名称)
    System.name = System.shell();
    //对版本号进行过滤过处理
    System.version = System.versionFilter(System.version);

  } catch (e) {
    console.log("error");
  }
  return {
    client: System
  };

})(window);

//#region  格式化时间
Date.prototype.format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1,
    "d+": this.getDate(),
    "H+": this.getHours(),
    "h+":(this.getHours()>12 ? this.getHours()-12 : this.getHours()),//12小时制
    "m+": this.getMinutes(),
    "s+": this.getSeconds(),
    "q+": Math.floor(( this.getMonth() % 3 == 0 ? ( this.getMonth() / 3 ) : ( this.getMonth() / 3 + 1 ) )),//季度
    "S": this.getMilliseconds()
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substring(4 - RegExp.$1.length))
  }
  if(new RegExp("(h+)").test(fmt)){
    var h=this.getHours()
    if(h>12){
      h="PM"
    }
    else{
      h="AM"
    }
    fmt+=" "+h
  }
  for (var k in o) {
    let str="00"
    if(new RegExp("(S)").test(k)){
      str="000"
    }
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1 == 1 ? (o[k]) : (str + o[k]).substring(("" + o[k]).length)))
    }
  }
 
  return fmt
}
String.prototype.format=function (fmt) {
  var str=''
  if(this){
    str=new Date(this).format(fmt)
  }
  return str
}

//#endregion

//全局组件
import MyMenu from './components/MyMenu';
Vue.component(MyMenu.name, MyMenu);
import MyList from './components/MyList';
Vue.component(MyList.name, MyList);
import MyLogin from './components/MyLogin';
Vue.component(MyLogin.name, MyLogin);
import MyRegister from './components/MyRegister';
Vue.component(MyRegister.name, MyRegister);
import IndexMianMenu from './components/IndexMianMenu';
Vue.component(IndexMianMenu.name, IndexMianMenu);
Vue.component('zw-dialog', () => import('@/components/common/dialog.vue'));
Vue.component('bread-crumb', () => import('@/components/common/bread-crumb.vue'));
Vue.component('password-input', () => import('@/components/common/password-input'));
Vue.component('scrollbar', () => import('@/components/common/scrollContainer.vue'));//自定义滚动条
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  data:{
    apiGlobalConfigData:[],//后台返回的全局配置数据
  },
  render: h => h(App)
}).$mount('#app')
