<!--
 * @Description: 登录组件
 * @Author: hai-27
 * @Date: 2020-02-19 20:55:17
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-01 15:34:08
 -->
<template>
  <div id="myLogin">
    <el-dialog
      title="登录"
      width="300px"
      center
      :visible.sync="isLogin"
      :close-on-click-modal="false"
    >
      <el-form
        :model="LoginUser"
        :rules="rules"
        status-icon
        ref="ruleForm"
        class="login-form"
      >
        <el-form-item prop="name">
          <el-input 
            prefix-icon="el-icon-user-solid"
            placeholder="请输入账号"
            v-model="LoginUser.name"
            @blur="setLoginUserName(LoginUser.name)"
          ></el-input>
        </el-form-item>
        <el-form-item prop="pass">
          <password-input :passWord.sync="LoginUser.pass"></password-input>
        </el-form-item>
        <el-form-item prop="verificationCode" v-if="loginCodeComp">
          <codeInput
            :verificationCode.sync="LoginUser.verificationCode"
            :phoneNumber="LoginUser.name"
            :sendBtn="sendBtn"
          ></codeInput>
        </el-form-item>
        <el-form-item>
          <el-button
            size="medium"
            type="primary"
            @click="Login"
            style="width: 100%"
            >登录</el-button
          >
          <div class="retrieve-pass">
            <span to="/user/exchange" class="text" @click="onRetrieve"
              >找回密码</span
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 密码重置 -->
    <MyRegister
      :registerTitle="registerTitle"
      :register="register"
      @fromChild="isRegister"
    ></MyRegister>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import codeInput from "@/components/common/code-input.vue";
export default {
  name: "MyLogin",
  components: {
    codeInput,
  },
  data() {
    // 用户名的校验方法
    let validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入用户名"));
      }
      this.$refs.ruleForm.validateField("pass");
      return callback();
      // 用户名以字母开头,长度在5-16之间,允许字母数字下划线
      // const userNameRule = /^[a-zA-Z][a-zA-Z0-9_]{4,15}$/;
      // if (userNameRule.test(value)) {
      //   this.$refs.ruleForm.validateField("pass");
      //   return callback();
      // } else {
      //   return callback(new Error("字母开头,长度5-16之间,允许字母数字下划线"));
      // }
      
    };
    // 密码的校验方法
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入密码"));
      }
      // 密码以字母开头,长度在6-18之间,允许字母数字和下划线
      //this.$regular.password.test(value)
      if (value.length>=7 && value.length<=50) {
        this.$refs.ruleForm.validateField("verificationCode");
        return callback();
      } else {
        return callback(
          // new Error("字母开头,长度6-18之间,允许字母数字和下划线")
          new Error("密码长度在7-50之间")
        );
      }
    };
    let validateRegCode = (rule, value, callback) => {
      if (value != "") {
        
        return callback();
      } else {
        return callback(new Error("请输入验证码"));
      }
    };
    return {
      LoginUser: {
        name: "",
        pass: "",
        verificationCode:""
      },
      // 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
      rules: {
        name: [{ validator: validateName, trigger: "blur" }],
        pass: [{ validator: validatePass, trigger: "blur" }],
        verificationCode: [{ validator: validateRegCode, trigger: "blur" }],
      },
      register: false,
      registerTitle: "找回密码",
      loginCodeComp:false,
      sendBtn: {
        text: "获取验证码",
        disabled: false,
      },
    };
  },
  computed: {
    // 获取vuex中的showLogin，控制登录组件是否显示
    isLogin: {
      get() {
        return this.$store.getters.getShowLogin;
      },
      set(val) {
        this.$refs["ruleForm"].resetFields();
        this.setShowLogin(val);
      },
    },
  },
  watch: {
    //保留账号
    isLogin(e) {
      if (e) {
        const loginCodeComp=localStorage.getItem("loginCodeComp")
        if(loginCodeComp)
        {
           this.loginCodeComp=loginCodeComp;
        }
       
        const storage = localStorage.getItem("LoginUser");
        if (storage) {
          const data = JSON.parse(storage);
          this.LoginUser.name = data.phone;
        }
      }
    },
  },
  methods: {
    ...mapActions(["setUser", "setShowLogin"]),
    setLoginUserName(val) {
      if (val) {
        const data = { phone: val };
        localStorage.setItem("LoginUser", JSON.stringify(data));
      }
    },
    Login() {
      // 通过element自定义表单校验规则，校验用户输入的用户信息
      this.$refs["ruleForm"].validate((valid) => {
        //如果通过校验开始登录
        if (valid) {
          let reqData = {
            phoneNumber: this.LoginUser.name,
            password: this.LoginUser.pass,
            verificationCode:this.LoginUser.verificationCode
          };
          this.$axios
            .post("/api/User/LoginUser", this.qs.stringify(reqData))
            .then((res) => {
              this.loginCodeComp=false
              // console.log("登录", res);
              // UserId代表登录成功，其他的均为失败
              if (res.data.UserId) {
                // 隐藏登录组件
                this.isLogin = false;
                // 登录信息存到本地
                let user = JSON.stringify(res.data);
                localStorage.setItem("user", user);
                // 登录信息存到vuex
                this.setUser(res.data);
                // 弹出通知框提示登录成功信息
                this.notifySucceed("登录成功");
                //登录后进入上次拦截的路由并清空
                setTimeout(() => {
                  // location.reload();
                  let session=sessionStorage.getItem("beforePath")
                  if(session){
                    const to=JSON.parse(session)
                    sessionStorage.setItem("beforePath","")
                    this.$router.push({path:to.path,query:to.query})
                  }
                  else{
                    location.reload();
                  }
                }, 1000);
              } else {
                // 清空输入框的校验状态
                this.$refs["ruleForm"].resetFields();
                // 弹出通知框提示登录失败信息
                this.notifyError(res.data);
              }
              //清空验证码款显示缓存
              localStorage.removeItem("loginCodeComp")
            })
            .catch((err) => {
              var res=err.response
              if(res.status==402){
                this.loginCodeComp=true
                localStorage.setItem("loginCodeComp", true);
              }
              else{
                 this.loginCodeComp=false
                localStorage.removeItem("loginCodeComp")
              }
              return Promise.reject(err);
            });
        } else {
          return false;
        }
      });
    },
    onRetrieve() {
      this.register = true;
    },
    isRegister(val) {
      this.register = val;
    },
  },
};
</script>
<style lang="scss" >
.login-form {
  .retrieve-pass {
    text-align: center;
    font-size: 12px;
  }
  .retrieve-pass .text {
    color: #99a9bf;
    cursor: pointer;
  }
  .text:hover {
    text-decoration: underline;
  }
}
.el-button--medium{
  background-color:   var(--ThemeColor,#ff6701);
    border-color:   var(--ThemeColor,#ff6701);
}
.el-button--medium:hover{
  opacity: 0.8;
  background-color:   var(--ThemeColor,#ff6701);
    border-color:   var(--ThemeColor,#ff6701);
}
</style>