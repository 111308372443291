/*
 * @Description: 全局变量
 * @Author: hai-27
 * @Date: 2020-02-23 13:40:18
 * @LastEditors: hai-27
 * @LastEditTime: 2021-02-27 04:29:16
 */

exports.install = function (Vue) {
  Vue.prototype.$target = ""; // 线上后端地址http://106.15.179.105:3000/api
  Vue.prototype.$currency = "";
  Vue.prototype.$imgUrl = "https://manage.yuntaotong.com";//图片服务器地址
  Vue.prototype.$host = "yuntaotong.com";//正式域名
  Vue.prototype.$icphost = "yuntaowulian.com";
  Vue.prototype.orderStatusText = function (val) {
    let txt = ""
    switch (val) {
      case 0:
        txt = "待兑换"
        break;
      case 1:
        txt = "待发货"
        break;
      case 2:
        txt = "待收货"
        break;
      case 3:
        txt = "已收货"
        break;
      case 4:
        txt = "待退款"
        break;
      case 5:
        txt = "已退款"
        break;
      case 6:
        txt = "退款失败"
        break;
      case 7:
        txt = "已取消"
        break;
      case 8:
        txt = "待退货"
        break;
      case 9:
        txt = "已退货"
        break;
      case 10:
        txt = "申请中"
        break;
      case 11:
        txt = "待审核"
        break;
    }
    return txt
  };
  Vue.prototype.hrefTencent = function () {
    //http://wpa.qq.com/msgrd?v=3&uin=123456789&site=qq&menu=yes
    window.open('http://wpa.qq.com/msgrd?v=3&uin= 741907417&site=qq&menu=yes')
  },
    // 封装提示成功的弹出框
    Vue.prototype.notifySucceed = function (msg) {
      this.$notify({
        title: "成功",
        message: msg,
        type: "success",
        offset: 100
      });
    };
  // 封装提示失败的弹出框
  Vue.prototype.notifyError = function (msg) {
    this.$notify.error({
      title: "温馨提示",
      dangerouslyUseHTMLString: true,
      message: msg,
      offset: 100
    });
  };
  Vue.prototype.loading = function (text) {
    this.$loading({
      lock: true,
      text: text,
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });

  };
  Vue.prototype.notifyInfo = function (msg, title = '消息') {
    this.$notify.info({
      title: title,
      message: msg
    });
  }
}