<!--
 * @Description: 列表组件，用于首页、全部商品页面的商品列表
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-04-05 13:22:22
 -->
<template>
  <div id="myList" class="myList">
    <ul>
      <li v-for="(item,index) in goolds" :key="index" >
       <!-- <div :class="['angle-box',{'isjd':item.GoodsFromType==1}]"><span class="desc">{{item.GoodsFromType==1?'智选':'严选'}}</span></div> -->
        <el-popover placement="top" :ref="`popover-${index}`" trigger="hover"> 
          <p>确定删除吗？</p>
          <div style="text-align: right; margin: 10px 0 0">
            <el-button type="primary" size="mini" @click="deleteCollect(item,index)"
              >确定</el-button
            >
          </div>
          <i
            class="el-icon-error delete"
            slot="reference"
            v-show="isDelete"
          ></i>
        </el-popover>
        <router-link :to="{ path: '/goods/info', query: getQuery(item) }">
          <!-- <img :src="$target +item.Photo" alt /> -->
          <el-image :src="$target + imgUrl(item.Photo)" class="img" alt>
            <div slot="error" class="image-slot">
              <!-- <i class="el-icon-picture-outline"></i> -->
            </div>
          </el-image>
          <h2 :title="item.Name"><span :class="['product-tag', { isjd: item.GoodsFromType == 1 }]">{{ item.GoodsFromType == 0 ? "严选" : "智选" }}</span> {{ item.Name }}</h2>
          <h3>{{ item.Lable ? item.Lable : item.Description }}</h3>
          <p>
            <span>{{ item.Price.toFixed(2) }} {{ $currency }}</span>
            <!-- <span
              v-show="item.Price != item.product_selling_price"
              class="del"
            >{{item.item.product_selling_price}}元</span> -->
          </p>
        </router-link>
      </li>
      <li v-show="isMore && goolds.length >= 1" id="more">
        <router-link :to="{ path: '/goods', query: { categoryID: classID } }">
          浏览更多
          <i class="el-icon-d-arrow-right"></i>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "MyList",
  // list为父组件传过来的商品列表
  // isMore为是否显示“浏览更多”
  // props: ["list", "isMore", "isDelete"],
  props: {
    list: {
      type: Array,
      default: () => {},
    },
    isMore: {
      type: Boolean,
      default: false,
    },
    isDelete: {
      type: Boolean,
      default: false,
    },
    categoryID: {
      type: Number,
      default: 0,
    },
    search:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      goolds: [],
      classID: 0,
    };
  },
  watch: {
    list(e) {
      this.goolds = e;
    },
  },
  created() {
    this.goolds = this.list;
    this.classID = this.categoryID;
  },
  computed: {
    getQuery() {
      return function (date) {
        var query = { productID: date.Id ,search: this.search};
        if (this.isDelete) {
          query.productID = date.GoodsId;
          query.skuId = date.GoodsSkuId;
        }
        return query;
      };
    },
    imgUrl(){
      return function(Photo){
        const url=Photo.split(",")
        return url[0]
      }
    }
    // 通过list获取当前显示的商品的分类ID，用于“浏览更多”链接的参数
    // categoryID: function() {
    //   let categoryID = [];
    //   console.log(this.list,'myList')
    //   if (this.list != "") {
    //     for (let i = 0; i < this.list.length; i++) {
    //       const id = this.list[i].category_id;
    //       if (!categoryID.includes(id)) {
    //         categoryID.push(id);
    //       }
    //     }
    //   }
    //   return categoryID;
    // }
  },
  methods: {
    deleteCollect(item,index) {
      this.$axios
        .post("/api/VouchersGoods/DelFavourite",this.qs.stringify( {
          Id: item.Id,
        }))
        .then((res) => {
          this.list.remove(item);
          this.notifySucceed("成功删除");
          this.$refs["popover-"+index][0].doClose()
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    onChange(date, id) {
      this.goolds = date;
      this.classID = id;
      // console.log(this.goolds,"onChange")
      // this.$emit("update:list", this.goolds)
    },
  },
};
</script>
<style scoped>
.myList ul li {
  z-index: 1;
  float: left;
  width: 225px;
  height: 280px;
  padding: 10px 0px;
  margin: 0 0 14.5px 13.7px;
  background-color: #fff;
   position: relative;
  overflow: hidden;
}
.myList ul li:hover {
  z-index: 2;
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(0, -2px, 0);
  transform: translate3d(0, -2px, 0);
}
.myList ul li .img {
  display: block;
  width: 160px;
  height: 160px;
  background: url(../assets/imgs/imgError.jpeg) no-repeat 50%;
  background-size: 160px;
  margin: 0 auto;
}
.myList ul li h2 {
  margin: 20px 10px 0;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  text-align: center;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /*多行在这里修改数字即可*/
  -webkit-box-orient: vertical;
  line-height: 22px;
}
.myList ul li h3 {
  margin: 5px 10px;
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: #b0b0b0;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.myList ul li p {
  margin: 10px 10px 10px;
  text-align: center;
  color: #ff6700;
}
.myList ul li p .del {
  margin-left: 0.5em;
  color: #b0b0b0;
  text-decoration: line-through;
}
.myList #more {
  text-align: center;
  line-height: 280px;
}
.myList #more a {
  font-size: 18px;
  color: #333;
}
.myList #more a:hover {
  color: #ff6700;
}
.myList ul li .delete {
  position: absolute;
  top: 5px;
  right: 5px;
  display: none;
}
.myList ul li:hover .delete {
  display: block;
  z-index: 100;
}
.myList ul li .delete:hover {
  color: #ff6700;
}

</style>