<!--
 * @Description: 个人中心菜单组件
 * @Author: yisp
 * @Date: 2021-10-12
 * @LastEditors: yisp
 * @LastEditTime: 2020-03-08 12:11:13
 -->
 <template>
  <div class="mianMenu" id="mianMenu">
    <el-menu
      default-active="1"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
    >
      <el-submenu index="1">
        <template slot="title">
          <!-- <i class="el-icon-location"></i> -->
          <span>账户中心</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="1-1">我的云淘豆</el-menu-item>
          <el-menu-item index="1-2">账户设置</el-menu-item>
          <el-menu-item index="1-3">地址管理</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>
 <script>
export default {
  props: ["val"],
  name: "IndexMianMenu",
  data(){
      return {
          type:1
      };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>
 
 