/*
*订单信息
* 
*/
export default {
    state: {
        orderInfo: []
    },
    getters: {
        getOrderInfo: (state) => (id) => {
            var data=state.orderInfo.find(item => item.id ==id )
            return  data
        }
    },
    mutations: {
        setOrderInfo(state, data) {
            let mode = state.orderInfo.find(item => item.id == data.id)
            if (mode) {
                mode = {...Object.assign(mode, data),}
            }
            else {
                state.orderInfo.push(data)
            }

        },
        removeOrder(state, data) {
            state.orderInfo.remove(data)
        }
    },
    actions: {
        setOrderInfo({ commit }, data) {
            commit('setOrderInfo', data);
        },
        removeOrder({ commit }, data) {
            commit('removeOrder', data);
        },
    }
}